import * as React from 'react';
import { useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";

import ProductCard from "../components/productcard.js"
import ProductRow from "../components/productrow.js"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Card
  } from '@mui/material';

import ProductsSearch  from '../components/products-search';

export default function ProductsDialog({ setOpen, open, products }) {

    const {  t } = useTranslation();

    const [view, setView] = useState("grid");
    const [filter, setFilter] = useState("");
    const [displayedData, setdisplayedData] = useState([]);
    const [sort, setSort] = useState("new");
    useEffect(() => {
        setdisplayedData(products);
        let newData = [];
        products.forEach(
        array => {
            if( array.attributes.filename.indexOf(filter) !== -1 ||  array.attributes.description.indexOf(filter) !== -1) {
            newData.push(array);
            };
        }
        )
        setdisplayedData(sortData(newData));    
    // eslint-disable-next-line react-hooks/exhaustive-deps
     },[filter,sort]);

    function sortData(arrayData){
        let sorted=[]; 
        if(sort==="old"){
            sorted = arrayData.sort(function(a, b) {
                return new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt);
            });
        }
        if(sort==="new"){
            sorted = arrayData.sort(function(a, b) {
                return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
            });
        }
        if(sort==="big"){
            sorted = arrayData.sort(function(a, b) {
                return b.attributes.size - a.attributes.size;
            });
        }
        if(sort==="small"){
            sorted = arrayData.sort(function(a, b) {
                return a.attributes.size - b.attributes.size;
            });
        }
        return sorted;
    }  

    const handleClose = () => {
        setOpen(false);
    };

    return (

        <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
            <Box sx={{ p: 2 }}>
                <DialogTitle>
                    <Typography variant="h4" component={"div"}>
                    {t("Products")}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: 4 }}>
                    <Box sx={{ paddingY:3}}>
                    <ProductsSearch view={view} setView={setView} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort}/>
                    </Box>
                    {view==="grid" && 
                    <Grid container spacing={3} columns={{ xs: 1, sm: 2, md: 2, lg:2, xl:2 }}>
                        {displayedData.map((product, index) => (
                            <Grid size={1} key={index} >
                                <ProductCard data={product.attributes}/>
                            </Grid>
                        ))}
                    </Grid>
                    }
                    {view==="list" && 
                    <Box>
                    <Card>
                        <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell sx={{width:100}}>
                            {t("File")}
                            </TableCell>
                            <TableCell>
                            {t("Description")}
                            </TableCell>
                            <TableCell sx={{width:80}}>
                            {t("Date")}
                            </TableCell>
                            <TableCell align="right" sx={{width:40}}>
                                
                            </TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {displayedData.map((file, index) => (
                            <ProductRow key={index} data={file} id={index}/>              
                        ))}
                        </TableBody>
                    </Table>
                    </Card>
                    </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>{t("Close")}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}