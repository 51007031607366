import { useEffect, useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import logo from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import PasswordChecklist from "react-password-checklist"
import {setNewPassword} from "../utils/interfaceS3";

export default function NewPassword() {

    const { i18n , t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    const [code, setCode] = useState("")
    const [password, setPassword] = useState("")
	const [passwordAgain, setPasswordAgain] = useState("")
    const [passwordValid, setPasswordValid] = useState(false)

    const mystyle = {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
        wordWrap: 'break-word'
    }

    useEffect(() => {
        if (localStorage.getItem('languageMirukuru') !== null) {          
            i18n.changeLanguage(localStorage.getItem('languageMirukuru'));
        }
    }, [i18n]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            await setNewPassword(`${location.state.email}`,code,password);
            enqueueSnackbar(`${t("SuccessNewPassword")}`, {variant:'success'} );
            navigate("/login");
        }catch (e) {
            console.log(e);
            if (e.name === 'CodeMismatchException') {
                enqueueSnackbar(`${t("CodeMismatchError")}`, {variant:'error'} );
            }else{
                enqueueSnackbar(`${t("ErrorNewPassword")}`, {variant:'error'} );
            }
        }
      }

    const content = (
        <>

            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
                sx={{ px: 3, maxWidth: '700px' }}
            >

                <Grid xs={12}>
                <CardMedia
                        component="img"
                        alt="logo mirukuru"
                        width="100px"
                        image={logo}
                        sx={{ px:4, py:3 }}
                    />
                    <Card sx={{ p: 5 }}>

                        <Stack
                            spacing={3}
                            sx={{ mb: 3 }}
                        >
                            <Typography variant="h4">
                            {t("NewPassword")}
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("NewPasswordDesc")}
                            </Typography>
                        
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <Stack spacing={3}>
                            <TextField
                                    fullWidth
                                    label={t("Code")}
                                    type="code"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label={t("NewPassword")}
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label={t("ReNewPassword")}
                                    type="password"
                                    value={passwordAgain}
                                    onChange={e => setPasswordAgain(e.target.value)}
                                />
                            <br/>    
                            <PasswordChecklist
                                style={mystyle}
                                rules={["minLength","specialChar","number","capital","lowercase","match"]}
                                minLength={8}
                                value={password}
                                valueAgain={passwordAgain}
                                messages={{
                                    minLength: t("minLengthPW"),
                                    specialChar: t("specialCharPW"),
                                    number: t("numberPW"),
                                    capital: t("capitalPW"),
                                    lowercase: t("lowercasePW"),
                                    match: t("matchPW"),
                                }}
                                onChange={(isValid) => {setPasswordValid(isValid)}}
                            />
                            </Stack>
                            <Button
                                disabled={!passwordValid}
                                fullWidth
                                size="large"
                                sx={{ mt: 3 }}
                                type="submit"
                                variant="contained"
                            >
                                {t("Continue")}
                            </Button>
                        </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </>
    );

        return (
            <>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                        {content}
                </Grid>
            </>
        );

}