import React, { useRef, useEffect, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
//import "../assets/css/potree.css";

const Potree = window.Potree

function PotreeViewer({ filename, description, metadata, hierarchy, octree }) {
  const { t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const potreeRenderArea = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    let viewer;
    try{
      viewer = new Potree.Viewer(potreeRenderArea.current);

      viewer.setEDLEnabled(true);
      viewer.setFOV(50);
      viewer.setPointBudget(1_000_000);
      viewer.loadSettingsFromURL();
      viewer.setBackground("gradient"); 
      viewer.setDescription(description);
  
      viewer.loadGUI(() => {
        if (localStorage.getItem('languageMirukuru') !== null) {          
          viewer.setLanguage(localStorage.getItem('languageMirukuru'));
        }else{
          viewer.setLanguage("en");
        }
      });
  
      Potree.loadPointCloud(
        metadata,
        hierarchy,
        octree,
        filename,
        (e) => {
          let scene = viewer.scene;
          let pointcloud = e.pointcloud;
          let material = pointcloud.material;
          material.size = 1;
          material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
          material.activeAttributeName = "elevation";
          material.shape = Potree.PointShape.SQUARE;
          scene.addPointCloud(pointcloud);
  
          viewer.fitToScreen();
        }
      );
    }catch(e){
      console.log(e);
      enqueueSnackbar(`${t("ErrorPotree")}`, {variant:'warning'} );
      navigate(-1);
    }

    return () => {
      if (viewer) {
        //console.log(document.body.innerHTML);

        viewer.renderer.clear();
        viewer = null;

        // Clear the profile window elements
        const profileWindow = document.getElementById("profile_window");
        if (profileWindow) {
          profileWindow.parentNode.removeChild(profileWindow);
        }
      }
    };

  }, [description, filename, hierarchy, metadata, octree, enqueueSnackbar, navigate, t]);
  return (
    <Fragment>
      <div id="potree_render_area" ref={potreeRenderArea}></div>
      <div id="potree_sidebar_container"></div>
    </Fragment>
  );
}

export default PotreeViewer;
