import axios from "axios";

export async function newActivity(userID,jwt, _filename, _type) {

    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify({
        filename: _filename,
        user: userID,
        type: _type
    }))
    axios.post(`${global.API_URL}/api/activities`, dataForm, {
        headers: {
            Authorization: `Bearer ${jwt}`,
        }
    }).then(res => {
        console.log(res);
    }).catch(err => {
        console.log(err);
    });
}

export async function getActivities(id,jwt) {
    let res = await axios.get(`${global.API_URL}/api/activities?populate=*`, {
        headers: {
            Authorization:
                `Bearer ${jwt}`,
        },
    });
    let activities = res.data.data;
    let filteredActivities = activities.filter(item => item.attributes.user.data.id === id);
    return filteredActivities;
}