import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import FileCard from "./filecard.js"
import FileRow from "./filerow.js"
import { useTranslation } from "react-i18next";
import FilesSearch  from './files-search';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Card
  } from '@mui/material';
  import { useState, useEffect} from 'react';

export default function DataList({ data, reloadProjects }) {

    const {  t } = useTranslation();

    const [view, setView] = useState("grid");
    const [filter, setFilter] = useState("");
    const [displayedData, setdisplayedData] = useState([]);
    const [sort, setSort] = useState("new");
    useEffect(() => {
        setdisplayedData(data);
        let newData = [];
        data.forEach(
        array => {
            if( array.attributes.filename.indexOf(filter) !== -1 ||  array.attributes.description.indexOf(filter) !== -1) {
            newData.push(array);
            };
        }
        )
        setdisplayedData(sortData(newData));    
    // eslint-disable-next-line react-hooks/exhaustive-deps
     },[filter,sort]);

    function sortData(arrayData){
        let sorted=[]; 
        if(sort==="old"){
            sorted = arrayData.sort(function(a, b) {
                return new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt);
            });
        }
        if(sort==="new"){
            sorted = arrayData.sort(function(a, b) {
                return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
            });
        }
        if(sort==="big"){
            sorted = arrayData.sort(function(a, b) {
                return b.attributes.size - a.attributes.size;
            });
        }
        if(sort==="small"){
            sorted = arrayData.sort(function(a, b) {
                return a.attributes.size - b.attributes.size;
            });
        }
        return sorted;
    }  

    const projectEmpty = data.length === 0;

    if (projectEmpty) {
        return (
            <Typography
                color="text.secondary"
                variant="body1"
                sx={{ p: 3 }}
            >
                {t("ProjectEmpty")}
            </Typography>
        );
    }

    return (
        <>
            <Box sx={{ paddingLeft:2,paddingRight:2 }}>
            <FilesSearch view={view} setView={setView} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort}/>
            </Box>
            {view==="grid" && 
            <Grid container spacing={3} sx={{ paddingLeft:2,paddingRight:2 }} columns={{ xs: 1, sm: 2, md: 3, lg:4, xl:4 }}>
            {displayedData.map((file, index) => (
                <Grid key={index} size={1}>
                    <FileCard data={file} reloadProjects={reloadProjects} />
                </Grid>
            ))}
            </Grid>}
            {view==="list" && 
            <Box sx={{ paddingLeft:2,paddingRight:2 }}>
            <Card>
                <Table>
                <TableHead>
                <TableRow>
                    <TableCell sx={{width:150}}>
                    {t("File")}
                    </TableCell>
                    <TableCell>
                    {t("Description")}
                    </TableCell>
                    <TableCell sx={{width:100}}>
                    {t("Date")}
                    </TableCell>
                    <TableCell align="right" sx={{width:100}}>
                    {t("Size")}
                    </TableCell>
                    <TableCell align="right" sx={{width:40}}>
                        
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {displayedData.map((file, index) => (
                    <FileRow key={index} data={file} id={index} reloadProjects={reloadProjects}/>              
                ))}
                </TableBody>
            </Table>
            </Card>
            </Box>
            }
        </>
    );
}