import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import FileCardAdmin from "./filecardadmin.js"
import { useTranslation } from "react-i18next";
import {
    Box,
    Stack
  } from '@mui/material';

export default function ProjectList({ projects, userid }) {

    const {  t } = useTranslation();

    const userEmpty = projects.length === 0;

    if (userEmpty) {
        return (
            <Typography
                color="text.secondary"
                variant="body1"
                sx={{ p: 3 }}
            >
                {t("UserEmpty")}
            </Typography>
        );
    }

    return (
        <>
        {projects.map((project, index) => (
            <Box sx={{ paddingTop:4}} key={index}>
            <Box sx={{ padding:2}} >
            <Box sx={{ paddingTop:1, paddingLeft:2 }}>
            <Stack
            spacing={1}        
            >      
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
            >
                <Typography variant="h5">
                {project.title}
                </Typography>
            </Stack>
            <Typography
                color="text.secondary"
                variant="body1"
            >
                {project.description}
            </Typography>
            </Stack>
            </Box>
            <Grid container spacing={3} sx={{ paddingTop:2,paddingLeft:1,paddingRight:1, paddingBottom:1 }} columns={{ xs: 1, sm: 2, md: 3, lg:4, xl:4 }}>
            {project.data.map((file, index) => (
                <Grid
                    key={index}
                    size={1}
                >
                    <FileCardAdmin data={file} userid={userid}/>
                </Grid>
            ))}
            </Grid>
            </Box>
            </Box>
        ))}
        </>
    );
}