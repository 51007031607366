import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";

export default function ErrorViewerDialog({ setOpen, open}) {

    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h4" component={"div"}>
                            {t("ErrorConverterHead")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                        <Typography color="text.secondary" variant="h6" sx={{ pt: 2 }}>
                            {t("ErrorConverter1")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pl:2,pt: 1 }}>
                            - {t("ErrorConverter2")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pl:2,pt: 1 }}>
                            - {t("ErrorConverter3")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pt: 3 }}>
                            {t("ErrorConverter4")}
                        </Typography>
                        <Typography color="text.secondary" variant="h6" sx={{ pt: 3 }}>
                            {t("ErrorConverter5")}
                        </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleClose}>{t("OK")}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}