import { useState,useContext } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Backdrop from '@mui/material/Backdrop';

import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { AuthContext } from "../Auth/AuthContext";
import {getPresignedURLS3,getPresignedURLForViewer,isViewerReady} from "../utils/interfaceS3";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

import FilePresentIcon from '@mui/icons-material/FilePresent';
import ProductsDialogAdmin from "../dialog/products-dialog-admin.js"

export const FilePopoverAdmin = (props) => {

  const {  t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const {idToken, region, idpool } = useContext(AuthContext);

  const { anchorEl, onClose, open, data, userid } = props;

  const [isViewerLoading, setIsViewerLoading] = useState(false);
  // const [openDialogShareFile, setOpenDialogShareFile] = useState(false);
  // const [openDialogDeleteFile, setOpenDialogDeleteFile] = useState(false);
  const [openDialogProducts, setOpenDialogProducts] = useState(false);
  // const [urlLink, setUrlLink] = useState("");

  async function handleDownloadFileClick () {
    try{
      const url = await getPresignedURLS3(userid,idToken, region, idpool,`${data.filename}`,3600);
      window.location.href = url;
    }catch{
      enqueueSnackbar(`${t("errorDownloadFile")}`, {variant:'error'} );
    }
  };

  const handleProductsClick = num => {
    setOpenDialogProducts(true);
    onClose();
  };

  async function handleView () {
    setIsViewerLoading(true);
    let viewerReady = await isViewerReady(userid,idToken, region, idpool,`${data.filename}`);
    if(viewerReady){
      const {metadata,hierarchy,octree} = await getPresignedURLForViewer(userid,idToken, region, idpool,`${data.filename}`,86400);
      const filename=data.filename;
      const description=data.description;
      setIsViewerLoading(false);
      navigate("/viewer-page/", { state: { filename:filename, description:description, metadata:metadata, hierarchy:hierarchy, octree:octree } });     
    }else{
      setIsViewerLoading(false);
      enqueueSnackbar(`${t("ViewerNotReady")}`, {variant:'info'} );
    }
    onClose();
  };

  return (
    <>
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={isViewerLoading}
      >     
        <Grid
        container
        alignItems="center"
        justify="center"
        alignContent="center"
        >
        <Grid xs={12} align="center" sx={{ py: 3}}>
        <CircularProgress color="inherit" size="5rem"/>
        </Grid>
        <Grid xs={12} align="center">
            <Typography variant="h5" color="inherit">
            <b>{t("GettingViewerReady")}</b>
            </Typography>
        </Grid>
        </Grid>
      </Backdrop>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 160 } }}
      >
        <MenuList
          disablePadding
          dense
          sx={{
            p: '8px',
            '& > *': {
              borderRadius: 1
            }
          }}
        >
            <MenuItem onClick={(event) => handleView(event)}>
              <ListItemIcon>
                <VisibilityIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("View")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={(event) => handleProductsClick(event)}>
              <ListItemIcon>
                <FilePresentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("Products")}</ListItemText>
            </MenuItem>
          <MenuItem onClick={(event) => handleDownloadFileClick(event)}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Download")}</ListItemText>
          </MenuItem>
          {/* <MenuItem disabled={demo} onClick={(event) => handleShareFileClick(event)}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Share")}</ListItemText>
          </MenuItem>
          <MenuItem disabled={demo} onClick={(event) => handleDeleteFileClick(event)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Delete")}</ListItemText>
          </MenuItem> */}
        </MenuList>
      </Popover>
      <ProductsDialogAdmin userid={userid} id={data.id} open={openDialogProducts} setOpen={setOpenDialogProducts} />
     </>
  );
};

FilePopoverAdmin.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
