import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from "react-i18next";

export default function AboutDialog({ setOpen, open}) {

    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h3" component={"div"}>
                            Mirukuru {t("OnlineStorage")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                        <Box sx={{ py: 3 }}>
                        <Typography variant="h6">
                            {t("Version : ")} 1.0
                        </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                        <Typography variant="h5">
                            {t("About")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pt: 1 }}>
                            {t("AboutInfo")}
                        </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                        <Typography variant="h5">
                            {t("SoftwareStructure")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pt: 1 }}>
                            {t("SoftwareStructureInfo")}
                        </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                        <Typography variant="h5">
                            {t("Potree")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pt: 1 }}>
                            {t("PotreeInfo")}
                            <Link
                                onClick={() => window.location.href = 'https://github.com/potree/potree/blob/develop/LICENSE'}
                                underline="hover"
                                variant="body2"
                            >
                                2-Clause BSD License
                            </Link>.
                        </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                        <Typography variant="h5">
                            {t("Contact")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pt: 1 }}>
                            {t("ContactInfo")}<Link
                                    onClick={() => window.location.href = 'mailto:contact-user-guest@mirukuru.co.jp'}
                                    underline="hover"
                                    variant="body2"
                                >
                                    {t("ContactUs")}
                                </Link>.
                        </Typography>
                        </Box>
                        <Box sx={{ py: 3 }}>
                        <Typography variant="h6">
                            {t("ThankYou")}
                        </Typography>
                        <Typography color="text.secondary" variant="body1" sx={{ px:2,pt: 3 }}>
                            {t("TeamSign")}
                        </Typography>
                        </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleClose}>{t("OK")}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}