import axios from "axios";
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import ProjectDashboard from "../components/projectDashboard.js"
import UserDashboard from "../components/userDashboard.js"
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import SideNav from './side-nav';
import SideNavAdmin from './side-nav-admin';
import { TopNav } from './top-nav';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import NewProjectDialog from "../dialog/new-project-dialog.js"
import NewDataDialog from "../dialog/new-data-dialog.js"
import EditProjectDialog from "../dialog/edit-project-dialog.js"
import DeleteProjectDialog from "../dialog/delete-project-dialog.js"

const SIDE_NAV_WIDTH = 280;
const SIDE_NAV_ADMIN_WIDTH = 340;

function Main() {

    const { user, 
        signOut, 
        accessToken, 
        idToken, 
        setJwt, 
        usage, setUsage, 
        maxSpace, setMaxSpace, 
        setMaxFileSize, 
        setDemo,
        setRegion, 
        setIdpool,
        adminMode,
        setIsAdmin,
        setCloudinary_cloud,
        setCloudinary_api_key,
        setCloudinary_api_secret,
        setCloudinary_preset
     } = useContext(AuthContext);

    const [language, setLanguage] = useState("jp");
    const {  t } = useTranslation();

    const LayoutRoot = styled('div')(({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: SIDE_NAV_WIDTH
        }
    }));

    const LayoutAdminRoot = styled('div')(({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: SIDE_NAV_ADMIN_WIDTH
        }
    }));

    const LayoutContainer = styled('div')({
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%'
    });

    const [loadingData, setLoadingData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {

        if (localStorage.getItem('languageMirukuru') !== null) {          
            setLanguage(localStorage.getItem('languageMirukuru'));
        }

        if (user) {
            //console.log(user);
            setLoadingData(true);
            if(adminMode){
                console.log("Admin Mode On");
                retrieveUsers();
            }else{
                retrieveProjects();
            }
            
        }else{
            console.log("Signout useeffect");
            signOut().then(res => {
                let path = `/login`; 
                navigate(path);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user,adminMode]);

    function findArrayElementById(array, id) {
        return array.find((element) => {
            return element.id === id;
        })
    }

    const [projectSelectedId, setProjectSelectedId] = useState(null);
    const [userHasNoProject, setUserHasNoProject] = useState(true);

    async function getStrapiUser(){
        let jwtStrapi="";
        let userStrapi={};
        try{
            let request = `${global.API_URL}/api/auth/cognito/callback?id_token=${idToken}&access_token=${accessToken}`;
            let res = await axios.get(request);
            jwtStrapi = res.data.jwt;
            setJwt(jwtStrapi);
            userStrapi = await axios.get(`${global.API_URL}/api/users/me?populate=*`, {
                headers: {
                    Authorization:
                        `Bearer ${jwtStrapi}`,
                },
            });
            setUserAuth(userStrapi.data);
            setMaxSpace(userStrapi.data.space);
            setMaxFileSize(userStrapi.data.maxFileSize);
            setDemo(userStrapi.data.demo);
            setIsAdmin(userStrapi.data.admin)
            getCredentials(jwtStrapi);
        }catch{
            console.log("cannot retrieve strapi user");
            signOut().then(res => {
                let path = `/login`; 
                navigate(path);
            })
        }
        return {
            jwtStrapi: jwtStrapi,
            userStrapi: userStrapi
        };
    }

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    async function retrieveUsers() {
        setLoading(true);
        //Retrieve strapi user from cognito token
        const { jwtStrapi } = await getStrapiUser();

        let res = await axios.get(`${global.API_URL}/api/users?populate[projects][populate][0]=data`, {
            headers: {
                Authorization:
                    `Bearer ${jwtStrapi}`,
            },
        });
        let users = res.data;
        users.sort(function(a, b) {
            var textA = a.email.toUpperCase();
            var textB = b.email.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        //console.log(users);
        setUsers(users);

        if(!userSelected){
            setUserSelected(users[0])
        }else{
            let newUserSelected=users.find(item => item.id === userSelected.id);
            setUserSelected(newUserSelected)
        }
        setLoading(false);
    }

    async function retrieveProjects() {
        setLoading(true);
            //Retrieve strapi user from cognito token
            const { jwtStrapi, userStrapi } = await getStrapiUser();

            let ids = [];
            let userProjects = [];
            if (userStrapi.data.projects !== undefined) {
                for (let i = 0; i < userStrapi.data.projects.length; i += 1) {
                    ids.push(userStrapi.data.projects[i].id);
                }

                let res = await axios.get(`${global.API_URL}/api/projects?populate[data][populate][0]=results`, {
                    headers: {
                        Authorization:
                            `Bearer ${jwtStrapi}`,
                    },
                });
                let projs = res.data.data;

                for (let i = 0; i < projs.length; i += 1) {
                    if (ids.includes(projs[i].id)) {
                        userProjects.push(projs[i]);
                    }
                }
            }

            let space = getSpaceUsage(userProjects);
            setUsage(space);

            if (userProjects.length > 0) {
                setUserHasNoProject(false);
            } else {
                setUserHasNoProject(true);
                setIsFirstTime(true);
            }
            setProjects(userProjects);
            //console.log(userProjects);
            if (isFirstTime) {
                setProjectSelected(userProjects[0]);
                setIsFirstTime(false);
            } else {
                if (projectSelectedId === null) {
                    setProjectSelected(userProjects[0]);
                } else {
                    setProjectSelected(findArrayElementById(userProjects, projectSelectedId));
                }
            } 
        setLoading(false);       
    }

    async function getCredentials(jwt)
    {
        let res = await axios.get(`${global.API_URL}/api/credentials`, {
            headers: {
                Authorization:
                    `Bearer ${jwt}`,
            },
        });
        let response = res.data.data[0].attributes;   
        setRegion(response.region);
        setIdpool(response.identity_pool);
        setCloudinary_cloud(response.cloudinary_cloud);
        setCloudinary_api_key(response.cloudinary_api_key);
        setCloudinary_api_secret(response.cloudinary_api_secret);
        setCloudinary_preset(response.cloudinary_preset);
    }

    function getSpaceUsage(projects){
        let space=0;
        for (let i = 0; i < projects.length; i += 1) {
            let proj = projects[i].attributes;
            for (let j = 0; j < proj.data.data.length; j += 1) {
                let d = proj.data.data[j];
                space+=parseFloat(d.attributes.size);
            }
        }
        return space/1e9;
    }

    const [projects, setProjects] = useState([]);

    const [userAuth, setUserAuth] = useState({});
    const [projectSelected, setProjectSelected] = useState(null);
    useEffect(() => {
        if (projectSelected !== null) {
            setLoadingData(false);
        }
    }, [projectSelected]);
    const [openNav, setOpenNav] = useState(false);
    const [openDialogProject, setOpenDialogProject] = useState(false);
    const [openDialogData, setOpenDialogData] = useState(false);
    const [openDialogEditProject, setOpenDialogEditProject] = useState(false);
    const [openDialogDeleteProject, setOpenDialogDeleteProject] = useState(false);

    const handleNewProject = () => {
        setOpenDialogProject(true);
        setOpenNav(false);
    };

    const handleDeleteProject = () => {
        setOpenDialogDeleteProject(true);
        setOpenNav(false);
    };

    const handleEditProject = () => {
        setOpenDialogEditProject(true);
        setOpenNav(false);
    };

    const handleNewData = () => {
        setOpenDialogData(true);
    };

    const callBackGetProjects = () => {
        setLoadingData(true);
        if (projectSelected !== undefined) setProjectSelectedId(projectSelected.id);
    };

    const callBackGetUsers = () => {
        retrieveUsers();
    };

    const callBackGetProjectsAfterDeletion = () => {
        setIsFirstTime(true);
        setLoadingData(true);
        setProjectSelectedId(null);
    };

    useEffect(() => {
        if (loadingData) retrieveProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [loadingData]);

    return (
        <div className="Main">
            {loading ?
                <Grid container direction="row" alignItems="center" justify="center" style={{ minHeight: '90vh' }}>
                    <Grid align="center" size={12}>
                        <Stack spacing={3} alignItems="center">
                            <CircularProgress size="6rem" />
                            <Typography variant="h3">
                                Retrieving Data
                            </Typography>
                            <Typography variant="h5" color="text.secondary">
                                Please wait
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                : 
                <>
                    {adminMode ?
                    <>
                        <TopNav onNavOpen={() => setOpenNav(true)} language={language} setLanguage={setLanguage} projects={projects}/>
                        <SideNavAdmin onClose={() => setOpenNav(false)} open={openNav}  users={users} userSelected={userSelected} setUserSelected={setUserSelected}/>
                        <LayoutAdminRoot>
                            <LayoutContainer>
                                <Box sx={{ flexGrow: 1, margin:1 }}>
                                    <UserDashboard
                                        userSelected={userSelected} reloadUsers={callBackGetUsers}
                                    />
                                </Box>
                            </LayoutContainer>
                        </LayoutAdminRoot>
                    </>
                    :
                    <>
                    <TopNav onNavOpen={() => setOpenNav(true)} language={language} setLanguage={setLanguage} projects={projects}/>
                    <SideNav onClose={() => setOpenNav(false)} open={openNav} userHasNoProject={userHasNoProject} handleClickOnNewProject={handleNewProject} projects={projects} projectSelected={projectSelected} setProjectSelected={setProjectSelected} usage={usage} maxSpace={maxSpace}
                    />
                    {(!userHasNoProject) ?
                        <>
                            <LayoutRoot>
                                <LayoutContainer>
                                    <Box sx={{ flexGrow: 1, margin:1 }}>
                                        <ProjectDashboard
                                            projectSelected={projectSelected}
                                            handleClickOnNewData={handleNewData}
                                            handleClickOnEditProject={handleEditProject}
                                            handleClickOnDeleteProject={handleDeleteProject}
                                            reloadProjects={callBackGetProjects}
                                        />
                                    </Box>
                                </LayoutContainer>
                            </LayoutRoot>

                            <NewDataDialog open={openDialogData} setOpen={setOpenDialogData} userId={userAuth.id} projectSelected={projectSelected} reloadProjects={callBackGetProjects} />
                            <EditProjectDialog open={openDialogEditProject} setOpen={setOpenDialogEditProject} projectSelected={projectSelected} reloadProjects={callBackGetProjects} />
                            <DeleteProjectDialog open={openDialogDeleteProject} setOpen={setOpenDialogDeleteProject} projectSelected={projectSelected} reloadProjects={callBackGetProjectsAfterDeletion} />
                        </>:
                        <LayoutRoot>
                        <LayoutContainer>
                        <Box sx={{ flexGrow: 1, padding:2 }}>
                        <Stack spacing={3} alignItems="center">
                        <Typography variant="h6">
                            {t("Welcome")}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t("PleaseStart")}
                        </Typography>
                        </Stack>
                        </Box>
                        </LayoutContainer>
                        </LayoutRoot>
                        }
                    <NewProjectDialog open={openDialogProject} setOpen={setOpenDialogProject} userId={userAuth.id} handleNewProjectCreated={callBackGetProjects} />
                    </>
                }</>
            }
        </div>
    );
}

export default Main;