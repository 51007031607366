
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVert from "@mui/icons-material/MoreVert";
import { usePopover } from '../hooks/use-popover';
import { FilePopover } from './file-popover';
import {formatSizeUnits} from '../utils/fileManagement'


export default function FileRow({ id, data, reloadProjects }) {

    const filePopover = usePopover();

    const handleClickVertButton = (event) => {
        filePopover.handleOpen();
    };

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString();
    }

    return(
        <>
        <TableRow
        hover
        key={id}
        >
        <TableCell>
            <Typography
                            color="text.primary"                           
                            variant="body1"
                        >  
            <b>{data.attributes.filename}</b>
            </Typography>
        </TableCell>
        <TableCell>
            <Typography
                            color="text.secondary"                           
                            variant="body2"
                        >   
            {data.attributes.description}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography
                            color="text.secondary"                           
                            variant="body2"
                        >   
            {getFormattedDate(data.attributes.createdAt)}
            </Typography>
        </TableCell>
        <TableCell align="right">
            <Typography
                            color="text.secondary"                           
                            variant="body2"
                        >
            {formatSizeUnits(data.attributes.size)}
            </Typography>
        </TableCell>
        <TableCell align="right">
            <IconButton aria-label="settings" onClick={handleClickVertButton} ref={filePopover.anchorRef}>
                <MoreVert />
            </IconButton>
        </TableCell>
        </TableRow>
        <FilePopover
        anchorEl={filePopover.anchorRef.current}
        open={filePopover.open}
        onClose={filePopover.handleClose}
        data={data}
        reloadProjects={reloadProjects}
        />
        </>
    )
};