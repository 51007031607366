import PropTypes from 'prop-types';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import { useTranslation } from "react-i18next";
import { useContext, useState } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePopover } from '../hooks/use-popover';
import { AccountPopover } from './account-popover';
import { NotificationPopover } from './notification-popover';

import { styled } from '@mui/material/styles';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 68;

const StyledIconButton = styled(IconButton)({
  backgroundColor: '#0174BE',
  '&:hover': {
    backgroundColor: '#005c96', // Adjust hover background color
  },
  '&.Mui-disabled': {
    backgroundColor: '#9DA4AE', // Use theme color or custom color
  },
});

export const TopNav = (props) => {
  const { onNavOpen,language,setLanguage,projects } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover();
  const notificationPopover = usePopover();
  const { user,demo, adminMode } = useContext(AuthContext);

  const [notificationCount, setNotificationCount] = useState(
    projects.reduce((acc, project) => {
      const projectData = project.attributes.data.data || [];
      projectData.forEach(dataItem => {
        const resultsData = dataItem.attributes.results.data || [];
        resultsData.forEach(resultItem => {
          if (resultItem.attributes.viewed === false) {
            acc++;
          }
        });
      });
      return acc;
    }, 0)
  );

  const handleNotificationUpdate = (count) => {
    setNotificationCount(count);
  };
  
  const { t} = useTranslation();

  function stringAvatar(user) {
    if(user){
      if(user.name.split(' ').length>1){
        return {
          children: `${user.name.split(' ')[0][0]}${user.name.split(' ')[1][0]}`.toUpperCase(),
        };
      }
  
      if(user.name.split(' ').length===1){
        return {
          children: `${user.name.split(' ')[0][0]}`.toUpperCase(),
        };
      }else{
        return ''
      }
    }
  }

  return (
    <>
      <Box
        component="header"
        sx={{
          backgroundColor: 'transparent',
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          {demo && 
          <Box sx={{px:2}}>
          <Typography color="text.secondary" variant="body3">
                  {t("Demo")}
          </Typography>
          </Box>
          }
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {!adminMode && <StyledIconButton sx={{
                bgcolor: '#0174BE',
                cursor: 'pointer',
                height: 40,
                width: 40,
                transition: 'all 0.3s ease-in-out',
                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.14)',
                  transform: 'scale(1.05)',
                },
                '&:active': {
                  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.24)',
                  transform: 'scale(0.95)',
                },
              }}
              disabled={notificationCount===0}
              onClick={notificationPopover.handleOpen}
              ref={notificationPopover.anchorRef}>
              <Badge
                badgeContent={notificationCount}
                color="error"
              >
                <SvgIcon>
                  <NotificationsNoneOutlinedIcon style={{ color: 'white' }}/>
                </SvgIcon>
              </Badge>
            </StyledIconButton>}
            <Avatar
              {...stringAvatar(user)}
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                bgcolor: '#0174BE',
                cursor: 'pointer',
                height: 40,
                width: 40
              }}/>
          </Stack>
        </Stack>
      </Box>

      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
        language={language}
        setLanguage={setLanguage}
      />
      <NotificationPopover
        anchorEl={notificationPopover.anchorRef.current}
        open={notificationPopover.open}
        onClose={notificationPopover.handleClose}
        projects={projects}
        onUpdateNotificationCount={handleNotificationUpdate}
      />
    </>
    
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func
};
