import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid2';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 8,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

export default function Usage({ value, max }) {

    const {  t } = useTranslation();

    return (
        <Grid
            container
            direction="column"
            spacing={2}
        >
            <Grid xs={12}>
                <BorderLinearProgress
                    value={(parseFloat(value)/parseFloat(max))*100}
                    variant="determinate"
                />
            </Grid>
            <Grid xs={12} textAlign='center'>
                <Typography variant="subtitle2" color="neutral.400">
                 {Math.round(value * 10) / 10} {t("Moused")} {max} {t("Momax")}
                </Typography>
            </Grid>
        </Grid>
    );
}