import { useContext, useState } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Flag from 'react-flagkit';

import AboutDialog from "../dialog/about-dialog"

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#0174BE' : '#0174BE',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#0174BE',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, language, setLanguage} = props;

  const {  i18n, t } = useTranslation();

  const { user, signOut, isAdmin, adminMode, setAdminMode } = useContext(AuthContext);

  const [openDialogAbout, setOpenDialogAbout] = useState(false);

  let navigate = useNavigate(); 

  const toggleSwitch = () => {
    setAdminMode(!adminMode);
  };

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    //let's store it for the next login
    localStorage.setItem('languageMirukuru', event.target.value);
  };

  const handleSignOut = (e) => {
      
    signOut().then(res => {
        let path = `/login`; 
        navigate(path);
      })
    };

    const handleAbout = (e) => {
      setOpenDialogAbout(true);
    };  

  return (
    <>
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { mt: 1, width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 3
        }}
      >
        <Typography variant="overline">
          {t("Account")}
        </Typography>
        {user && 
        <>
        <Typography
          color="text.secondary"
          variant="body2"
        >
           {user.name}
        </Typography>
      
      {user["custom:company"] && 

        <Box sx={{pt: 1}}>
        <Typography variant="overline">
         {t("Company")}
        </Typography>
        {user && 
        <>
        <Typography
          color="text.secondary"
          variant="body2"
        >
           {user["custom:company"]}
        </Typography></>}
      </Box>}</>}
      </Box>   
      {isAdmin && 
      <>
        <Divider />
        <Box sx={{paddingX:3, paddingY:1}}>
        <FormControlLabel control={<IOSSwitch sx={{ m: 1 }}/>}  checked={adminMode} onChange={toggleSwitch} label={<Typography color="text.primary" variant="body2">Administrator</Typography>}/>
        </Box>   
      </>  
      }
      <Divider />
      <Box sx={{paddingX:3, paddingY:1}}>
      <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-form-control-label-placement"
        name="position"
        value={language}
        defaultValue={"jp"}
        onChange={handleChangeLanguage}
      >
        <FormControlLabel
          value="jp"
          control={<Radio />}
          label={<Box sx={{paddingTop:1}}><Flag country="JP" /></Box>}
          labelPlacement="end"
        />
        <FormControlLabel
          value="en"
          control={<Radio />}
          label={<Box sx={{paddingTop:1}}><Flag country="GB" /></Box>}
          labelPlacement="end"
        />
      </RadioGroup>  
      </FormControl>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={handleAbout}>
         <ListItemIcon>
              <InfoOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("AboutButton")}</ListItemText>
        </MenuItem>
      </MenuList>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Signout")}</ListItemText>
        </MenuItem>
      </MenuList>
    </Popover>
    <AboutDialog open={openDialogAbout} setOpen={setOpenDialogAbout}/>
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
