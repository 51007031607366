import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import AnimationIcon from '@mui/icons-material/Animation';

import ShareProductDialog from "../dialog/share-product-dialog.js"
import ThreeDialog from "../dialog/three-dialog.js"

import { AuthContext } from "../Auth/AuthContext";
import {getPresignedResultURLS3} from "../utils/interfaceS3";
import { useTranslation } from "react-i18next";

export const ProductPopover = (props) => {

  const {  t } = useTranslation();

  const { anchorEl, onClose, open , data} = props;

  const {user,idToken, region, idpool, demo } = useContext(AuthContext);

  const [openDialogShareProduct, setOpenDialogShareProduct] = useState(false);
  const [urlLink, setUrlLink] = useState("");

  async function handleDownloadProductClick () {
    const url = await getPresignedResultURLS3(user.sub,idToken, region, idpool,`${data.filename}`,3600);
    window.location.href = url;
  };

  async function handleShareProductClick() {
    setOpenDialogShareProduct(true);
    const url = await getPresignedResultURLS3(user.sub,idToken, region, idpool,`${data.filename}`,86400);
    setUrlLink(url);
    onClose();
  };

  const [openThreeDialog, setOpenThreeDialog] = useState(false);

  async function handleClickOpenThreeDialog () {
    setOpenThreeDialog(true);
    const url = await getPresignedResultURLS3(user.sub,idToken, region, idpool,`${data.filename}`,86400);
    setUrlLink(url);
  };

  const handleCloseThreeDialog = () => {
    setOpenThreeDialog(false);
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 160 } }}
      >
        <MenuList
          disablePadding
          dense
          sx={{
            p: '8px',
            '& > *': {
              borderRadius: 1
            }
          }}
        >          
        {data.filename && data.filename.split('.').pop() === 'ply' && <MenuItem disabled={demo} onClick={(event) => handleClickOpenThreeDialog(event)}>
        <ListItemIcon>
          <AnimationIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t("View")}</ListItemText>
      </MenuItem>}
          <MenuItem disabled={demo} onClick={(event) => handleDownloadProductClick(event)}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Download")}</ListItemText>
          </MenuItem>
          <MenuItem disabled={demo} onClick={(event) => handleShareProductClick(event)}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Share")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      <ShareProductDialog open={openDialogShareProduct} setOpen={setOpenDialogShareProduct} url={urlLink}/> 
      <ThreeDialog open={openThreeDialog} onClose={handleCloseThreeDialog} url={urlLink}/> 
    </>
  );
};

ProductPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
