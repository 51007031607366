import * as React from 'react';
import axios from "axios";
import { useState, useEffect, useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { AuthContext } from "../Auth/AuthContext";
import { useTranslation } from "react-i18next";
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import {SvgIcon} from '@mui/material';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import {uploadS3Presigned,fileExistsInS3} from "../utils/interfaceS3";
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CircularProgress from '@mui/material/CircularProgress';
import { FileUploader } from "react-drag-drop-files";
import { useSnackbar } from 'notistack';
import ProductRowAdmin from "../components/productrowadmin.js"

import { alpha } from '@mui/material/styles';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
  } from '@mui/material';

export default function ProductsDialogAdmin({ setOpen, open, id, userid }) {

    const { t } = useTranslation();

    const {jwt,idToken, region, idpool} = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [addMode, setAddMode] = useState(false);

    //fetch the products corresponding to this id
    useEffect(() => { 
        loadProducts();
        // eslint-disable-next-line
    },[id]);
   
    function loadProducts()
    {
        setLoading(true);
        axios.get(`${global.API_URL}/api/datas/${id}?populate=*`, {
        headers: {
            Authorization:
                `Bearer ${jwt}`,
        }}).then((response) => {
            //console.log(response.data.data.attributes.results.data);
            setLoading(false);
            setProducts(response.data.data.attributes.results.data);
            setAddMode(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setAddMode(false);
        });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddProductClick = () => {
        setAddMode(true);
    };

    const handleCancel = () => {
        setAddMode(false);
    };

    const [fileSelected, setFileSelected] = React.useState("");
    const [description, setDescription] = useState("");

    const handleChange = (file) => {
        //console.log(file);
        setFileSelected(file);
    };

    function hasWhiteSpace(s) {
        return s.indexOf(' ') >= 0;
      }

    const handleUploadProductClick = () => {
        setLoading(true);
        //check if file has been selected
        if(typeof fileSelected.name == 'string'){       

            //check if the filename contains ' '
            if(hasWhiteSpace(fileSelected.name)){
                enqueueSnackbar(`${t("ErrorFileNoSpace")}`, {variant:'warning'} );
                setLoading(false);
            }else{
                //check if file exists   
                fileExistsInS3(userid,idToken,fileSelected.name).then(res => {
                    //console.log(res);
                    if(!res){                   
                        newProduct(userid, fileSelected.name, description);  
                    }else{
                        enqueueSnackbar(`${t("ErrorAlreadyExists")}`, {variant:'warning'} );
                        setLoading(false);
                    }
                }) 
            }
        }else{
            enqueueSnackbar(`${t("ErrorNoFile")}`, {variant:'warning'} );
            setLoading(false);
        }     
    };

    function newProduct(userid, filename, description) {
        uploadS3Presigned(userid,idToken, region, idpool,filename,fileSelected).then(res => {
                const dataForm = new FormData();
                dataForm.append('data', JSON.stringify({
                    filename: `${global.DIR_PRODUCTS}/` +filename,
                    description: description
                }))
                axios.post(`${global.API_URL}/api/results`, dataForm, {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    }
                })
                    .then(res => {
                        updateDataWithNewProduct(id, res.data.data.id);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                        setFileSelected("");
                        enqueueSnackbar(`${t("ErrorUpload")}`, {variant:'error'} );
                    });
            })       
    }

    async function updateDataWithNewProduct(idData, idProduct) {

        let data = await axios.get(`${global.API_URL}/api/datas/${idData}?populate=*`, {
            headers: {
                Authorization:
                    `Bearer ${jwt}`,
            },
        });
        //console.log(data);
        //Add the id to the previous list of ids
        let newDataIds = [];
        let listFiles = data.data.data.attributes.results.data;
        for (let i = 0; i < listFiles.length; i += 1) {
            newDataIds.push(listFiles[i].id);
        }
        newDataIds.push(idProduct);
        //console.log(newDataIds);
        const dataForm = new FormData();
        dataForm.append('data', JSON.stringify({
            results: newDataIds,
        }))
        await axios.put(`${global.API_URL}/api/datas/${idData}`, dataForm, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'multipart/form-data'
            }
        });      
        enqueueSnackbar(`${t("UploadSuccess")}`, {variant:'success'} );
        setFileSelected("");
        loadProducts();
    }

    return (

        <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >     
                <Grid
                container
                alignItems="center"
                justify="center"
                alignContent="center"
                >
                <Grid xs={12} align="center" sx={{ py: 3}}>
                <CircularProgress color="inherit" size="5rem"/>
                </Grid>
                <br/>
                <Grid xs={12} align="center">
                    <Typography variant="h5" color="inherit">
                    <b>{t("UploadingContent")}</b>
                    </Typography>
                </Grid>
                </Grid>
            </Backdrop>
            <Box sx={{ p: 2 }}>
                <DialogTitle>
                    <Typography variant="h4" component={"div"}>
                    {t("Products")}
                    </Typography>
                </DialogTitle>
                <DialogContent>

                    
                    <Grid container spacing={2} sx={{ paddingTop:2}}>
                        {products.length>0 ? 
                        <>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell sx={{width:100}}>
                            {t("File")}
                            </TableCell>
                            <TableCell>
                            {t("Description")}
                            </TableCell>
                            <TableCell sx={{width:80}}>
                            {t("Date")}
                            </TableCell>
                            <TableCell align="right" sx={{width:40}}>
                                
                            </TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {products.map((product, index) => (
                            <ProductRowAdmin key={index} data={product} id={index} userid={userid} loadProducts={loadProducts}/>              
                        ))}
                        </TableBody>
                    </Table>  
                        </>
                        :
                        <Typography color="text.secondary" variant="body2">{t("NoProductAdmin")}</Typography>
                        }
                    </Grid>    
                    {addMode &&
                        <Stack spacing={3} sx={{ mt: 5 }}>
                        <Typography color="text.primary" variant="h6">{t("AddNewProductAdmin")}</Typography>
                        <FileUploader 
                        handleChange={handleChange} 
                        multiple={false}
                        name="file" 
                        hoverTitle=" "
                        children={
                            <Box>
                            <Paper variant="outlined"
                                sx={{
                                    borderColor: '#E5E7EB',
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    '&:hover': {
                                        backgroundColor: alpha('#111927', 0.04)
                                    }
                                }}>
                            <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            style={{ minHeight: '100px', padding:20 }}
                            >

                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                            >
                            <CloudUploadIcon style={{ color: 'gray' }}/><Typography variant="body2" color="text.secondary">{t("dragndrop")}</Typography>
                            </Stack>
                            </Grid>
                            </Paper>
                            </Box>}
                        />
                            {fileSelected && 
                            <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                            >
                            <AttachmentIcon style={{ color: 'gray' }}/>
                            <Typography variant="body2" color="text.secondary"><b>{fileSelected.name}</b></Typography>
                            </Stack>}
                            <TextField
                                fullWidth
                                label={t("Description")}
                                name="description"
                                type="text"
                                multiline
                                rows={3}
                                value={description}
                                onChange={(event) => { setDescription(event.target.value) }}
                            />
                        </Stack>
                    }   
                </DialogContent>
                <DialogActions>
                    {!addMode ?
                    <>
                    <Button onClick={handleClose}>{t("Close")}</Button>
                    <Button
                        variant="contained"
                        startIcon={(
                        <SvgIcon fontSize="medium">
                            <AddIcon />
                        </SvgIcon>
                        )}
                        onClick={handleAddProductClick}
                        >
                        {t("UploadProduct")}
                    </Button>
                    </>:
                    <>
                    <Button onClick={handleCancel}>{t("Cancel")}</Button>
                    <Button
                        variant="contained"
                        startIcon={(
                        <SvgIcon fontSize="medium">
                            <UploadIcon />
                        </SvgIcon>
                        )}
                        onClick={handleUploadProductClick}
                        >
                        {t("Upload")}
                    </Button>
                    </>    
                    }
                </DialogActions>
            </Box>
        </Dialog>
    );
}