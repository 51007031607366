import * as React from 'react';
import { useState, useContext } from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

import { AuthContext } from "../Auth/AuthContext";

export default function NewProjectDialog({ setOpen, open, userId, handleNewProjectCreated }) {

    const {  t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    const {jwt} = useContext(AuthContext);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreate = () => {
        setOpen(false);
        setIsLoading(true);
        newProject(title, description);
    };

    function newProject(title, description) {

            const dataForm = new FormData();
            dataForm.append('data', JSON.stringify({
                title: title,
                description: description,
                user: userId,
            }))
            axios.post(`${global.API_URL}/api/projects`, dataForm, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                }
            })
                .then(res => {
                    setIsLoading(false);
                    enqueueSnackbar(`${t("successCreateNewProject")}`, {variant:'success'} );
                    setTitle("");
                    setDescription("");
                    handleNewProjectCreated();
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false);
                    enqueueSnackbar(`${t("errorCreateNewProject")}`, {variant:'error'} );
                });
        
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h4" component={"div"}>
                            {t("NewProject")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("NewProjectDescription")}
                            </Typography>
                        </DialogContentText>
                        <Stack spacing={3} sx={{ mt: 3 }}>
                            <TextField
                                fullWidth
                                label={t("Title")}
                                name="title"
                                type="text"
                                value={title}
                                onChange={(event) => { setTitle(event.target.value) }}
                            />
                            <TextField
                                fullWidth
                                label={t("Description")}
                                name="description"
                                type="text"
                                multiline
                                rows={3}
                                value={description}
                                onChange={(event) => { setDescription(event.target.value) }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                        <Button variant="contained" onClick={handleCreate}>{isLoading ? `${t("Creating")} ...` : `${t("Create")}`}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}