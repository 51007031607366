import React from 'react';
import { useLocation } from "react-router-dom";

import PotreeViewer from "../components/PotreeViewer";

function ViewerPageWrapper() {
  const {state} = useLocation();
  const { filename, description, metadata, hierarchy, octree } = state;

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
      }}
    >
      <PotreeViewer
        filename={filename}
        description={description}
        metadata={metadata} 
        hierarchy={hierarchy} 
        octree={octree}
      />
    </div>
  );
}

export default ViewerPageWrapper;