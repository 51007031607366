import * as React from 'react';
import { useEffect,useContext } from 'react';
import  Button from '@mui/material/Button';
import  Stack from '@mui/material/Stack';
import  SvgIcon from '@mui/material/SvgIcon';
import  Typography from '@mui/material/Typography';
import  IconButton from '@mui/material/IconButton';
import  Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import DataList from './datalist';
import MoreVert from "@mui/icons-material/MoreVert";
import { usePopover } from '../hooks/use-popover';
import { ProjectPopover } from './project-popover';
import { useTranslation } from "react-i18next";
import { AuthContext } from "../Auth/AuthContext";

import UploadIcon from '@mui/icons-material/Upload';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function ProjectDashboard({ handleClickOnNewData, handleClickOnEditProject, handleClickOnDeleteProject, projectSelected, reloadProjects }) {

  const { demo } = useContext(AuthContext);

  const {  t } = useTranslation();

  const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
}

  useEffect(() => {
  }, [projectSelected]);

  const handleNewDataClick = (event) => {
    handleClickOnNewData();
  };

  const projectPopover = usePopover();

  return (
    <div>
      <Stack spacing={3}>
        <Grid
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: 'row', sm: 'row' }}
          sx={{ fontSize: '12px',paddingRight:2 ,paddingTop:2 }}
        >
        <Grid>
        <Box sx={{ paddingLeft:2 }}>
        <Stack
          spacing={1}        
        >      
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
          >
            <Typography variant="h4">
              {projectSelected.attributes.title}
            </Typography>
            <IconButton onClick={projectPopover.handleOpen} ref={projectPopover.anchorRef}>
              <MoreVert fontSize="large" />
            </IconButton>
          </Stack>
          <Typography
            color="text.secondary"
            variant="h5"
          >
            {projectSelected.attributes.description}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{ paddingTop:1 }}
          >
            <SvgIcon
                color="action"
                fontSize="small"
            ><AddCircleOutlineIcon/></SvgIcon>    
            <Typography variant="body2" color="text.secondary">
              {t("CreatedOn")}{getFormattedDate(projectSelected.attributes.createdAt)}    
            </Typography>
            <SvgIcon
                color="action"
                fontSize="small"
            ><RefreshIcon/></SvgIcon>
            <Typography variant="body2" color="text.secondary">
              {t("UpdatedOn")}{getFormattedDate(projectSelected.attributes.updatedAt)}
            </Typography>
          </Stack>
        </Stack>
        </Box>
        </Grid>
        <Grid>
        <Box sx={{ paddingLeft:2, py:2 }}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <Button
          disabled={demo}
            variant="contained"
            startIcon={(
              <SvgIcon fontSize="medium">
                <UploadIcon />
              </SvgIcon>
            )}
            onClick={(event) => handleNewDataClick(event)}
          >
            {t("UploadData")}
          </Button>
        </Stack>
        </Box>
        </Grid>
        </Grid>
        <DataList data={projectSelected.attributes.data.data} reloadProjects={reloadProjects} />
      </Stack>

      <ProjectPopover
        anchorEl={projectPopover.anchorRef.current}
        open={projectPopover.open}
        onClose={projectPopover.handleClose}
        handleClickOnEditProject={handleClickOnEditProject}
        handleClickOnDeleteProject={handleClickOnDeleteProject}
      />
    </div>
  );
}