import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid2';
import { AuthContext } from "../Auth/AuthContext";
import { getActivities } from "../utils/activityManagement";
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import ClockIcon from '@heroicons/react/24/solid/ClockIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import CircleStackIcon from '@heroicons/react/24/solid/CircleStackIcon';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { formatSizeUnits } from '../utils/fileManagement'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { PieChart,pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { DefaultizedPieValueType } from '@mui/x-charts/models';
import {formatSizeToGb} from '../utils/fileManagement'
import { LineChart } from '@mui/x-charts/LineChart';
import {
  lineElementClasses,
  markElementClasses,
} from '@mui/x-charts/LineChart';

import UserSettingDialog from "../dialog/user-setting-dialog.js"

import ProjectList from './projectlist';

import { useTranslation } from "react-i18next";

import { getStorageData } from "../utils/interfaceS3";

const palette = ['#2072AF', '#7BAFD4', '#4bc098', '#FF8042'];


export default function UserDashboard({ userSelected, reloadUsers }) {

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const { jwt, idToken, region, idpool } = useContext(AuthContext);

  const { t } = useTranslation();

  const [activityData, setActivityData] = useState([]);
  const [sizeUpload, setSizeUpload] = useState(0);
  const [sizeView, setSizeView] = useState(0);
  const [sizeProduct, setSizeProduct] = useState(0);
  const [sizeTotal, setSizeTotal] = useState(0);

  const [openDialogUserSetting, setOpenDialogUserSetting] = useState(false);

  const handleSettingUserClick = num => {
    setOpenDialogUserSetting(true);
  };

  const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  }

  function displayChart() {
    getActivities(userSelected.id, jwt).then(res => {
      //console.log(userSelected);       
      setActivityData(res);
    })
    getStorageData(userSelected.username, idToken, region, idpool).then(res => {
      setSizeUpload(res.sizeUpload);
      setSizeView(res.sizeView);
      setSizeProduct(res.sizeProduct);
      setSizeTotal(res.sizeUpload + res.sizeView + res.sizeProduct);
    })
  }

  const groupedData = activityData.reduce((acc, activity) => {
    const monthYear = new Date(activity.attributes.createdAt).toLocaleString('default', { month: 'long', year: 'numeric' });
    acc[monthYear] = (acc[monthYear] || 0) + 1;
    return acc;
  }, {});

  // Get the current date
  const currentDate = new Date();

  // Generate labels for the past 12 months
  const labels = [];
  const data = [];
  const dataLine = [];
  for (let i = 11; i >= 0; i--) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    let lab = date.toLocaleString('default', { month: 'long', year: 'numeric' })
    labels.push(lab);
    const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    let val = (groupedData[monthYear] || 0);
    data.push(val);
    dataLine.push({ name: lab, uploads: val })
  }

  let dataDoughnut = []
  if (sizeUpload > 1000000) {
    dataDoughnut.push({ label: t("Upload"), value: formatSizeToGb(sizeUpload) })
  }
  if (sizeView > 1000000) {
    dataDoughnut.push({ label: t("View"), value: formatSizeToGb(sizeView) })
  }
  if (sizeProduct > 1000000) {
    dataDoughnut.push({ label: t("Products"), value: formatSizeToGb(sizeProduct) })
  }

  const TOTAL = dataDoughnut.map((item) => item.value).reduce((a, b) => a + b, 0);
  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  useEffect(() => {
    if (userSelected) {
      displayChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  return (
    <div>
      <Stack spacing={3}>

        <Box sx={{ paddingX: 3, paddingY: 1 }}>
          <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '&:hover': {
              boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.06), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.04)',
              transform: 'none',
              }
          }}   >

            <CardHeader
              sx={{
                display: "flex",
                overflow: "hidden",
                "& .MuiCardHeader-content": {
                  overflow: "hidden"
                },
              }}
              action={
                <div>
                  <IconButton aria-label="settings" onClick={(event) => handleSettingUserClick(event)}>
                    <SettingsIcon />
                  </IconButton>
                </div>
              }
              title={
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  {userSelected &&
                    <>{userSelected.admin && <AdminPanelSettingsIcon />}
                      <Typography variant="h5">
                        {userSelected.email}
                      </Typography></>
                  }
                </Stack>
              }
              subheader={
                <Box sx={{ paddingY: 1 }}>
                  <Chip label={userSelected && userSelected.username} sx={{ color: 'neutral.500' }}>
                  </Chip>
                </Box>
              }
            />

    <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 12 }}>
      <Grid size={{ xs: 6, md: 4}}>  {/* Full width on small screens, half width on medium and up */}
        <Box height={300} display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ paddingX: 2, paddingY: 0 }}>
          <PieChart
            colors={palette}
            series={[
              {
                arcLabel: getArcLabel,
                data: dataDoughnut,
                innerRadius: 30,
                outerRadius: 100,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: 0,
                endAngle: 360,
                cx: 150,
                cy: 150,
              }
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontSize: 14,
              },
            }}
            height={300}  
            width={400}
          />
        </Box>
      </Grid>
      <Grid  size={{ xs: 6, md: 8}}>  {/* Full width on small screens, half width on medium and up */}
        <Box height={300} display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ paddingX: 2, paddingY: 0 }}>
          <LineChart
            height={300}
            series={[
              { data: data
              },
              
            ]}
            xAxis={[{ scaleType: 'point', data: labels }]}
            sx={{
              [`& .${lineElementClasses.root}`]: {
                stroke: '#2072AF',
                strokeWidth: 2,
              },
              [`& .${markElementClasses.root}`]: {
                stroke: '#2072AF',
                scale: '0.6',
                fill: '#fff',
                strokeWidth: 2,
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>

            <Divider />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={2}
              sx={{ p: 2 }}
            >
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
              >
                <SvgIcon
                  color="action"
                  fontSize="small"
                >
                  <ClockIcon />
                </SvgIcon>
                {userSelected && <Typography
                  color="text.secondary"
                  display="inline"
                  variant="body2"
                >
                  {lgUp && t("UserCreated")}{getFormattedDate(userSelected.createdAt)}
                </Typography>}
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
              >
                <SvgIcon
                  color="action"
                  fontSize="small"
                >
                  <CloudUploadIcon />
                </SvgIcon>
                <Typography
                  color="text.secondary"
                  display="inline"
                  variant="body2"
                  sx={{ pr: 2 }}
                >
                  {lgUp && t("Upload")}{formatSizeUnits(sizeUpload)}
                </Typography>
                <SvgIcon
                  color="action"
                  fontSize="small"
                >
                  <CircleStackIcon />
                </SvgIcon>
                <Typography
                  color="text.secondary"
                  display="inline"
                  variant="body2"
                >
                  {lgUp && t("Total")}{formatSizeUnits(sizeTotal)}
                </Typography>
              </Stack>
            </Stack>

          </Card>
        </Box>
        <Box sx={{ paddingX: 0 }}>
          {userSelected && <ProjectList projects={userSelected.projects} userid={userSelected.username} />}
        </Box>
      </Stack>
      {userSelected && <UserSettingDialog open={openDialogUserSetting} setOpen={setOpenDialogUserSetting} user={userSelected} reloadUsers={reloadUsers} />}
    </div>
  );
}