import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next)
  .init({
    fallbackLng: "jp",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          Login:"Login",
          Email:"Email Address",
          Password:"Password",
          DontHaveAccount:"Don't have an account?",
          ErrorLogin:"Error : Please verify your Login/Password.",
          Continue:"Continue",
          English:"English",
          Japanese:"Japanese",
          Account: "Account",
          Signout: "Sign out",
          OnlineStorage: "Online Storage",
          NewProject: "New Project",
          NoProjectYet: "Your account does not contain any project yet.",
          Needmorespace: "Need more space?",
          Getintouchwithus: "Get in touch with us",
          ContactUs: "Contact Us",
          Welcome: "Welcome to Mirukuru Online Storage!",
          PleaseStart:"Please start by creating a project.",
          Moused:"GB used on",
          Momax:"GB",
          NewProjectDescription:"To create a new project, please enter a title and a short description.",
          Title:"Title",
          Description:"Description",
          Cancel:"Cancel",
          Create:"Create",
          Creating:"Creating",
          ProjectEmpty:"This project is empty. Please upload some data.",
          UploadData:"Upload",
          Progress:"Progress",
          Edit:"Edit",
          Delete:"Delete",
          SelectALASFile:"Select a file you want to upload in the LAS or LAZ format, give a small description and attach an image.",
          LASLAZFile:"LAS/LAZ File (required)",
          Image:"Image",
          dragndrop:"Drag 'n' drop or click to select",
          Upload:"Upload ",
          Uploading:"Upload",
          ErrorUpload:"An error occured while uploading the data. Please try again.",
          ErrorType:"The file you provided is not in the LAS or LAZ format. Please select a LAS or LAZ file.",
          ErrorSpace:"The file you selected will not fit in your storage space. Get in touch with us to increase your storage space.",
          ErrorMaxFileSpace:"The file you selected exceed the maximum size. Please select a smaller file.",
          ErrorNoFile:"Please select one file.",
          UploadSuccess:"Data uploaded!",
          EditProject:"Edit Project",
          PleaseModify:"Please modify the project name and description.",
          NewTitle:"New Title",
          NewDescription:"New Description",
          Save:"Save",
          Saving:"Saving",
          Products:"Products",
          Download:"Download",
          Share:"Share",
          DeleteProject:"Delete Project",
          DeleteProjectDesc:"Any deleted project, including the attached data, cannot be recovered. Please, proceed with caution.",
          Deleting:"Deleting",
          errorDeleteProject:"An error occured while deleteing the project. Please try again.",
          successDeleteProject:"Project deleted!",
          errorEditProject:"An error occured while editing the project. Please try again.",
          successEditProject:"Project modified!",
          errorCreateNewProject:"An error occured during the project creation. Please check that you filled correctly the title and the description.",
          successCreateNewProject:"Project creation successful!",
          Close:"Close",
          ShareFile:"Share File",
          ShareProduct:"Share Product",
          ShareFileDesc:"A link to the file, valid for 24 hours, will be automatically copied to your clipboard. Feel free to share this link to your collaborators. Anybody with this link can download the file.",
          CreateLink:"Create Link",
          LinkSuccess:"The link has been copied to your clipboard!",
          DeleteFile:"Delete File",
          DeleteFileDesc:"Any deleted File cannot be recovered. The products associated will also be deleted. Please, proceed with caution.",
          successDeleteFile:"Data deleted!",
          errorDeleteFile:"An error occured during the data deletion, please try again.",
          ErrorAlreadyExists:"This file has already been uploaded on your account, please select another one.",
          ForgotPassword:"Forgot your password?",
          ResetPassword:"Reset Password",
          ResetPasswordDesc:"Please fill here your email. If your email corresponds to an account in our database, you will receive shortly a verification code for setting up your new password.",
          Send:"Send Code",
          SuccessResetPassword:"The code has been successfully sent to your email address.",
          ErrorResetPassword:"The email address your provided does not correspond to any registered account, Please verify that you entered the correct address.",
          NewPasswordDesc:"Please enter here the verification code you received by email and your new password.",
          Code:"Code",
          SuccessNewPassword:"Your password has been changed!",
          ErrorNewPassword:"An error occured while setting your new password. Please check your verification code.",
          errorDownloadFile:"An error occured while downloading the file. Please try again.",
          Searchfile:"Search file",
          Sortby:"Sort by",
          Date:"Date",
          Latest:"Latest",
          Oldest:"Oldest",
          Size:"Size",
          Biggest:"Biggest",
          Smallest:"Smallest",
          File:"File",
          View:"View",
          ErrorPotree:"An error occured during the rendering. Please try again.",
          NewPassword:"Enter your new Password",
          ReNewPassword:"Enter again your new Password",
          minLengthPW: "Password has at least 8 characters.",
					specialCharPW: "Password has special characters.",
					numberPW: "Password has a number.",
					capitalPW: "Password has a capital letter.",
          lowercasePW: "Password has a lowercase letter.",
					matchPW: "Passwords match.",
          TooManyAttempt:"Attempt limit exceeded, please try after some time.",
          CodeMismatchError:"The provided code doesn't match. Please check again your verification code.",
          ViewerNotReady:"The viewer for this file is not ready yet. Please try again after few minutes.",
          UploadingContent:"Uploading content",
          MaximumSize:"Maximum size",
          GettingViewerReady:"The viewer is getting ready.",
          AboutButton:"About",
          About:"About",
          AboutInfo:"Mirukuru Online Storage is a powerful software tool designed for storing and visualizing 3D point clouds online. It enables users to interactively explore and analyze point cloud data with ease.",
          SoftwareStructure:"Software Structure",
          SoftwareStructureInfo:"The software is built on a robust and scalable architecture that handles the secure storage of 3D point cloud data through Amazon Web Services and uses Potree, an open-source point cloud rendering framework, for high-quality and interactive visualization.",
          Potree:"Potree",
          PotreeInfo:"Mirukuru Online Storage uses Potree and Potree Converter for rendering massive point clouds in web browsers. Potree and its converter are renowned for their performance and compatibility with various LiDAR data formats. Both Potree and Potree Converter are under ",
          Contact:"Contact",
          ContactInfo:"For support, inquiries, or feedback, please ",
          ThankYou:"Thank you for choosing Mirukuru Online Storage!",
          TeamSign:"The Mirukuru Team",
          Demo:"This account is intended for demonstration purposes only.",
          UserEmpty:"This user did not upload any data yet.",
          ErrorFileNoSpace:"The name of the file should not contain space character (' '). Please change the file name and try again.",
          NoProductAdmin:"There is no product attached to this file yet.",
          UploadProduct:"Add product",
          AddNewProductAdmin:"Add a new product",
          ErrorConverterHead:"Sorry, the viewer is not available for this file",
          ErrorConverter1:"This can happen for the two following reasons:",
          ErrorConverter2:"Some points fall outside bounding box defined in the LAS header",
          ErrorConverter3:"The Global Encoding (GE) flag is not set to WKT, which is a requirement for Point Data Record Format 6 - 10.",
          ErrorConverter4:"Please try to repair the bounding box, e.g. using lasinfo with the -repair_bb argument, and/or verify that the Global Encoding (GE) flag is set to WKT, then upload your file again.",
          ErrorConverter5:"If the problem persists, do not hesitate to contact our team.",
          SuccessEditUser:"The user has been edited successfully.",
          ErrorEditUser:"An error occured during the user edition.",
          AccountActivity:"Account Activity",
          Storage:"Storage ",
          UserCreated:"User created on ",
          Total:"Total ",
          UserSettings1:"User Settings",
          UserSettings2:"Storage Space",
          UserSettings3:"Maximum size of the directory storing the files uploaded by this user.",
          AdminPanel:"Administration Panel",
          StorageStatistics:"Storage Statistics",
          Empty:"Empty",
          HasBeenAddedToTheFile:" has been added to ",
          CreatedOn:"Created on ",
          UpdatedOn:"Last updated on ",
          EditData:"Edit Data",
          PleaseModifyData:"Please provide a new description and/or a new image.",
          SuccessEditFile:"The file has been modified with success",
          ErrorEditFile:"An error occured during the file modification",         
        },
      },
      jp: {
        translation: {
          Login:"ログイン",
          Email:"メールアドレス",
          Password:"パスワード",
          DontHaveAccount:"アカウントをお持ちでないですか？",
          ErrorLogin:"エラー： ログイン/パスワードを確認してください。",
          Continue:"続ける",
          English:"英語",
          Japanese:"日本語",
          Account: "アカウント",
          Signout: "サインアウト",
          OnlineStorage: "オンラインストレージ",
          NewProject: "新プロジェクト",
          NoProjectYet: "アカウントにはまだプロジェクトがありません。",
          Needmorespace: "もっとスペースが必要ですか?",
          Getintouchwithus: "お問い合わせ",
          ContactUs: "連絡先",
          Welcome: "Mirukuru オンラインストレージへようこそ！",
          PleaseStart:"プロジェクトの作成から始めてください。",
          Moused:"GB を",
          Momax:"GB に使用",
          NewProjectDescription:"新しいプロジェクトを作成するには、タイトルと簡単な説明を入力してください。",
          Title:"タイトル",
          Description:"説明",
          Cancel:"キャンセル",
          Create:"作成",
          Creating:"創造する",
          ProjectEmpty:"このプロジェクトは空です。データをアップロードしてください。",
          UploadData:"アップロード",
          Progress:"進行状況",
          Edit:"エディット",
          Delete:"デリート",
          SelectALASFile:"LASまたはLAZ形式でアップロードしたいファイルを選択し、簡単な説明をつけて画像を添付してください。",
          LASLAZFile:"LAS/LAZファイル（必須）",
          Image:"画像",
          dragndrop:"ドラッグ＆ドロップまたはクリックで選択",
          Upload:"アップロード ",
          Uploading:"アップロード",
          ErrorUpload:"データのアップロード中にエラーが発生しました。もう一度お試しください。",
          ErrorType:"提供されたファイルはLASまたはLAZフォーマットではありません。LASまたはLAZファイルを選択してください。",
          ErrorSpace:"選択したファイルは、お客様のストレージスペースに収まりません。保管スペースを増やすには、当社までご連絡ください。",
          ErrorMaxFileSpace:"選択したファイルが最大サイズを超えています。より小さいファイルを選択してください。",
          ErrorNoFile:"ファイルを1つ選択してください。",
          UploadSuccess:"データは正常にアップロードされた！",
          EditProject:"編集プロジェクト",
          PleaseModify:"プロジェクト名と説明を修正してください。",
          NewTitle:"新しいタイトル",
          NewDescription:"新着情報",
          Save:"保存する",
          Saving:"保存",
          Products:"製品紹介",
          Download:"ダウンロード",
          Share:"シェア",
          DeleteProject:"プロジェクトの削除",
          DeleteProjectDesc:"削除されたプロジェクトは、添付されたデータも含めて復元できません。ご注意ください。",
          Deleting:"削除",
          errorDeleteProject:"プロジェクトの削除中にエラーが発生しました。もう一度やり直してください。",
          successDeleteProject:"プロジェクトは削除された",
          errorEditProject:"プロジェクトの編集中にエラーが発生しました。もう一度やり直してください。",
          successEditProject:"プロジェクトは変更された！",
          errorCreateNewProject:"プロジェクト作成中にエラーが発生しました。タイトルと説明が正しく記入されているか確認してください。",
          successCreateNewProject:"プロジェクトは成功した！",
          Close:"閉じる",
          ShareFile:"シェアファイル",
          ShareProduct:"シェア製品",
          ShareFileDesc:"24時間有効のファイルへのリンクが自動的にクリップボードにコピーされます。このリンクを共同作業者に自由にシェアしてください。このリンクがあれば、誰でもファイルをダウンロードすることができます。",
          CreateLink:"リンク作成",
          LinkSuccess:"リンクがクリップボードにコピーされました！",
          DeleteFile:"ファイルの削除",
          DeleteFileDesc:"削除されたファイルは復元できません。 関連製品も削除されます。ご注意ください。",
          successDeleteFile:"データは削除された！",
          errorDeleteFile:"データ削除中にエラーが発生しました。",
          ErrorAlreadyExists:"このファイルはすでにアカウントにアップロードされています。",
          ForgotPassword:"パスワードを忘れましたか？",
          ResetPassword:"パスワードのリセット",
          ResetPasswordDesc:"ここにあなたの電子メールを記入してください。あなたの電子メールが私たちのデータベースにあるアカウントに対応する場合、あなたは新しいパスワードを設定するための検証コードをまもなく受け取ります。",
          Send:"コードを送信",
          SuccessResetPassword:"コードがお客様のメールアドレスに送信されました。",
          ErrorResetPassword:"入力されたメールアドレスが登録されているアカウントと一致しません。",     
          NewPasswordDesc:"電子メールで受け取った認証コードと新しいパスワードをここに入力してください。",
          Code:"コード",
          SuccessNewPassword:"パスワードが変更されました！",
          ErrorNewPassword:"新しいパスワードの設定中にエラーが発生しました。認証コードを確認してください。",
          errorDownloadFile:"ファイルのダウンロード中にエラーが発生しました。もう一度お試しください。",
          Searchfile:"検索ファイル",
          Sortby:"ソート",
          Date:"日付",
          Latest:"最新",
          Oldest:"最高齢",
          Size:"サイズ",
          Biggest:"最大",
          Smallest:"最小",
          File:"ファイル",
          View:"表示",
          ErrorPotree:"レンダリング中にエラーが発生しました。もう一度お試しください。",
          NewPassword:"パスワードを入力してください。",
          ReNewPassword:"パスワードをもう一度入力してください。",
          minLengthPW: "パスワードは8文字以上。",
					specialCharPW: "パスワードに特殊文字が含まれている。",
					numberPW: "パスワードには番号がある。",
					capitalPW: "パスワードは大文字。",
          lowercasePW: "パスワードは小文字。",
					matchPW: "パスワードは一致する。",
          TooManyAttempt:"試行回数の制限を超えました。時間をおいてからやり直してください。",
          CodeMismatchError:"提供されたコードが一致しません。認証コードをもう一度確認してください。",
          ViewerNotReady:"このファイルのビューアはまだ準備ができていません。数分後に再度お試しください。",
          UploadingContent:"コンテンツのアップロード",
          MaximumSize:"最大サイズ",
          GettingViewerReady:"ビューアーは準備中",
          AboutButton:"お知らせ",
          About:"Mirukuruオンラインストレージについて",
          AboutInfo:"Mirukuruオンラインストレージは、3D点群をオンラインで保存し、可視化するために設計された強力なソフトウェアツールです。点群データをインタラクティブに探索し、簡単に分析することができます。",
          SoftwareStructure:"ソフトウェアの構造",
          SoftwareStructureInfo:"このソフトウェアは、Amazon Web Servicesを通じて3D点群データを安全に保管し、オープンソースの点群レンダリングフレームワークであるPotreeを使用して高品質でインタラクティブなビジュアライゼーションを実現する、堅牢でスケーラブルなアーキテクチャで構築されている。",
          Potree:"Potree",
          PotreeInfo:"MirukuruオンラインストレージはPotreeとPotree Converterを使用し、ウェブブラウザで大規模な点群をレンダリングしています。Potreeとそのコンバーターは、そのパフォーマンスと様々なLiDARデータフォーマットとの互換性で有名です。PotreeとPotree Converterはどちらも ",
          Contact:"連絡先",
          ContactInfo:"サポート、お問い合わせ、ご意見はこちら ",
          ThankYou:"Mirukuruオンラインストレージをご利用いただきありがとうございます！",
          TeamSign:"Mirukuru チーム",
          Demo:"このアカウントはデモンストレーションのみを目的としています。",
          UserEmpty:"このユーザーはまだデータをアップロードしていない。",
          ErrorFileNoSpace:"ファイル名にスペース（' '）を含めてはいけません。ファイル名を変更して再試行してください。",
          NoProductAdmin:"このファイルにはまだ製品は添付されていない。",
          UploadProduct:"製品を追加する",
          AddNewProductAdmin:"新製品を追加する",
          ErrorConverterHead:"申し訳ありませんが、このファイルではビューアは使用できません。",
          ErrorConverter1:"これは以下の2つの理由で起こりうる：",
          ErrorConverter2:"いくつかの点は、LASヘッダーで定義されたバウンディング・ボックスから外れる。",
          ErrorConverter3:"Global Encoding（GE）フラグが WKT に設定されていません。これは Point Data Record Format 6 - 10 の要件です。",
          ErrorConverter4:"例えば、-repair_bb引数付きのlasinfoを使用してバウンディングボックスを修復するか、グローバルエンコーディング（GE）フラグがWKTに設定されていることを確認してから、ファイルを再度アップロードしてください。",
          ErrorConverter5:"それでも問題が解決しない場合は、遠慮なく弊社までご連絡ください。",
          SuccessEditUser:"ユーザーが正常に編集されました。",
          ErrorEditUser:"ユーザーエディット中にエラーが発生しました。",
          AccountActivity:"アカウント活動",
          Storage:"ストレージ ",
          UserCreated:"ユーザー作成日 ",
          Total:"合計 ",
          UserSettings1:"ユーザー設定",
          UserSettings2:"収納スペース",
          UserSettings3:"このユーザーがアップロードしたファイルを格納するディレクトリの最大サイズ。",
          AdminPanel:"管理パネル",
          StorageStatistics:"ストレージ統計",
          Empty:"空",
          HasBeenAddedToTheFile:" が追加された。ファイル: ",
          CreatedOn:"作成日 ",
          UpdatedOn:"最終更新日 ",
          EditData:"データ編集",
          PleaseModifyData:"新しい説明文または新しい画像を提供してください。",  
          SuccessEditFile:"ファイルが変更されました。",
          ErrorEditFile:"ファイル変更中にエラーが発生しました。",
        },
      },
    },
  });

export default i18n;