
import {
    S3Client,
    GetObjectCommand,
    DeleteObjectCommand,
    HeadObjectCommand,
    PutObjectCommand,
    ListObjectsV2Command 
  } from '@aws-sdk/client-s3';
  import {Upload} from '@aws-sdk/lib-storage'
  import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
  import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
  import {
    ForgotPasswordCommand,
    CognitoIdentityProviderClient,
    ConfirmForgotPasswordCommand
  } from "@aws-sdk/client-cognito-identity-provider";

  function setupS3Client(idToken, region, idpool ){

    try{
        let COGNITO_ID = `cognito-idp.${region}.amazonaws.com/${global.USERPOOLID}`;// 'COGNITO_ID' has the format 'cognito-idp.REGION.amazonaws.com/COGNITO_USER_POOL_ID'
        let loginData = {
        [COGNITO_ID]: idToken,
        };
      
        // Create an S3 client
        const s3Client = new S3Client({
            region: region,
            credentials: fromCognitoIdentityPool({
            clientConfig: { region: region },
            identityPoolId: idpool,
            logins: loginData
            }),
        });  
        return s3Client;
    }catch{
        console.log("NotAuthorizedException");
    }
  }

  export async function fileExistsInS3(directory,idToken, region, idpool, filename){
    let client = setupS3Client(idToken, region, idpool);
    const input = {
      "Bucket": `${global.BUCKET}`,
      "Key": directory+ `/${global.DIR_UPLOADS}/` +filename
    };
    try {
      const command = new HeadObjectCommand(input);
      await client.send(command);
      //console.log("Object exists");
      //console.log(response);
      return true;
    } catch (e) {
      //console.log("Object does not exist");
      return false;
    }
  }  

  export async function uploadS3(directory,idToken, region, idpool, key, body, setProgress) {
    let c = setupS3Client(idToken, region, idpool);
    
    let k=directory+ `/${global.DIR_UPLOADS}/` +key;

    try {
      const upload = new Upload({
        client: c,
        params: {Bucket:`${global.BUCKET}`,Key:k,Body:body},
      });
    
      upload.on("httpUploadProgress", progress => {
        let currentProgress=parseFloat(progress.loaded)/parseFloat(progress.total);
        //console.log('Current Progress', currentProgress);
        setProgress(Math.round(currentProgress*100));      
      });
    
      await upload.done();
    } catch (err) {
      console.error(err);
  }

    };

async function keyExistsInS3(idToken, region, idpool, key){
    let client = setupS3Client(idToken, region, idpool);
    try {
      const bucketParams = {
          Bucket: `${global.BUCKET}`,
          Key: key,
      };
      const cmd = new HeadObjectCommand(bucketParams);
      const data = await client.send(cmd);

      // I always get 200 for my testing if the object exists
      const exists = data.$metadata.httpStatusCode === 200;
      return exists;
  } catch (error) {
      if (error.$metadata?.httpStatusCode === 404) {
          // doesn't exist and permission policy includes s3:ListBucket
          return false;
      } else if (error.$metadata?.httpStatusCode === 403) {
          // doesn't exist, permission policy WITHOUT s3:ListBucket
          return false;
      }
   }
  }  

  //upload a file on presigned url
  export async function uploadS3Presigned(directory, idToken, region, idpool, filename, file, onUploadProgress) {
    let client = setupS3Client(idToken, region, idpool);
    const input = {
      "Bucket": `${global.BUCKET}`,
      "Key": directory+ `/${global.DIR_PRODUCTS}/` +filename
    };
    const command = new PutObjectCommand(input);
    let url = await getSignedUrl(client, command, { expiresIn: 3600 });
    //console.log(url);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    };
    const response = await fetch(url, requestOptions);
    //console.log(response);
    return response;
  }

  export async function getPresignedURLS3 (directory,idToken, region, idpool, filename, time) {
        let client = setupS3Client(idToken, region, idpool);
        const input = {
          "Bucket": `${global.BUCKET}`,
          "Key": directory+ `/${global.DIR_UPLOADS}/` +filename
        };
        console.log("input",input);
        const command = new GetObjectCommand(input);
        const url = await getSignedUrl(client, command, { expiresIn: time });
        return url;
    };  
    
    export async function getPresignedResultURLS3 (directory,idToken, region, idpool, filename, time) {
      let client = setupS3Client(idToken, region, idpool);
      const input = {
        "Bucket": `${global.BUCKET}`,
        "Key": directory+ '/' +filename
      };
      //console.log("input",input);
      const command = new GetObjectCommand(input);
      const url = await getSignedUrl(client, command, { expiresIn: time });
      return url;
  }; 

  export async function isViewerReady(directory,idToken, region, idpool, filename){
    let dir = filename.replace(/\.[^/.]+$/, "");
    let metadata_key = directory + `/${global.DIR_VIEWS}/` + dir + '/metadata.json';
    let metadata_exists = await keyExistsInS3(idToken, region, idpool, metadata_key);

    let hierarchy_key = directory + `/${global.DIR_VIEWS}/` + dir + '/hierarchy.bin';
    let hierarchy_exists = await keyExistsInS3(idToken, region, idpool, hierarchy_key);

    let octree_key = directory + `/${global.DIR_VIEWS}/` + dir + '/octree.bin';
    let octree_exists = await keyExistsInS3(idToken, region, idpool, octree_key);

    console.log("metadata_exists",metadata_exists);
    console.log("hierarchy_exists",hierarchy_exists);
    console.log("octree_exists",octree_exists);

    return metadata_exists && hierarchy_exists && octree_exists;
  }

  export async function getStorageData(directory,idToken, region, idpool){
    let client = setupS3Client(idToken, region, idpool);
    const inputV = { // ListObjectsV2Request
      Bucket: `${global.BUCKET}`,
      Prefix: directory+ `/${global.DIR_VIEWS}/`,
    };
    const commandV = new ListObjectsV2Command(inputV);
    const responseV = await client.send(commandV);
    let totalV = 0;
    if(responseV.Contents){
      responseV.Contents.forEach(obj => {
        totalV += obj.Size;
      });
    }

    const inputU = { // ListObjectsV2Request
      Bucket: `${global.BUCKET}`,
      Prefix: directory+ `/${global.DIR_UPLOADS}/`,
    };
    const commandU = new ListObjectsV2Command(inputU);
    const responseU = await client.send(commandU);
    let totalU = 0;
    if(responseU.Contents){
      responseU.Contents.forEach(obj => {
        totalU += obj.Size;
      });
    }

    const inputP = { // ListObjectsV2Request
      Bucket: `${global.BUCKET}`,
      Prefix: directory+ `/${global.DIR_PRODUCTS}/`,
    };
    const commandP = new ListObjectsV2Command(inputP);
    const responseP = await client.send(commandP);
    let totalP = 0;
    if(responseP.Contents){
      responseP.Contents.forEach(obj => {
        totalP += obj.Size;
      });
    }

    return {sizeUpload:totalU,sizeView:totalV,sizeProduct:totalP}
  }

  export async function getStorageTotal(idToken, region, idpool){
    let client = setupS3Client(idToken, region, idpool);
    const inputV = { // ListObjectsV2Request
      Bucket: `${global.BUCKET}`,
    };
    const commandV = new ListObjectsV2Command(inputV);
    const responseV = await client.send(commandV);
    let totalU = 0;
    let totalV = 0;
    let totalP = 0;
    if(responseV.Contents){
      responseV.Contents.forEach(obj => {
        if(obj.Key.includes('/'+global.DIR_UPLOADS+'/'))totalU += obj.Size;
        if(obj.Key.includes('/'+global.DIR_VIEWS+'/'))totalV += obj.Size;
        if(obj.Key.includes('/'+global.DIR_PRODUCTS+'/'))totalP += obj.Size;      
      });
    }
    return {sizeUpload:totalU,sizeView:totalV,sizeProduct:totalP};
  }

  export async function isViewerError(directory,idToken, region, idpool, filename){
    let dir = filename.replace(/\.[^/.]+$/, "");
    let error_key = directory + `/${global.DIR_VIEWS}/` + dir + '/error_potree_converter.txt';
    let error_exists = await keyExistsInS3(idToken, region, idpool, error_key);
    console.log("error_exists",error_exists);
    return error_exists;
  }

  export async function getPresignedURLForViewer (directory,idToken, region, idpool, filename, time) {
      let dir = filename.replace(/\.[^/.]+$/, "");
      let client = setupS3Client(idToken, region, idpool);

      const input_metadata = {
        "Bucket": `${global.BUCKET}`,
        "Key": directory + `/${global.DIR_VIEWS}/` + dir + '/metadata.json'
      };
      //console.log("input",input);
      const command_metadata = new GetObjectCommand(input_metadata);
      const url_metadata = await getSignedUrl(client, command_metadata, { expiresIn: time });

      const input_hierarchy = {
        "Bucket": `${global.BUCKET}`,
        "Key": directory + `/${global.DIR_VIEWS}/` + dir + '/hierarchy.bin'
      };
      //console.log("input",input);
      const command_hierarchy = new GetObjectCommand(input_hierarchy);
      const url_hierarchy = await getSignedUrl(client, command_hierarchy, { expiresIn: time });

      const input_octree = {
        "Bucket": `${global.BUCKET}`,
        "Key": directory + `/${global.DIR_VIEWS}/` + dir + '/octree.bin'
      };
      //console.log("input",input);
      const command_octree = new GetObjectCommand(input_octree);
      const url_octree = await getSignedUrl(client, command_octree, { expiresIn: time });

      return {metadata:url_metadata,hierarchy:url_hierarchy,octree:url_octree};
  };    

  export  async function deleteProductS3 (directory,idToken, region, idpool, filename) {
    let client = setupS3Client(idToken, region, idpool);
    const input = {
      "Bucket": `${global.BUCKET}`,
      "Key": directory+ `/${global.DIR_PRODUCTS}/` +filename
    };
    //console.log("input",input);
    await client.send(new DeleteObjectCommand(input));
  }

    export  async function deleteFileS3 (directory,idToken, region, idpool, filename) {
        let client = setupS3Client(idToken, region, idpool);
        const input = {
          "Bucket": `${global.BUCKET}`,
          "Key": directory+ `/${global.DIR_UPLOADS}/` +filename
        };
        //console.log("input",input);
        await client.send(new DeleteObjectCommand(input));

        let dir = filename.replace(/\.[^/.]+$/, "");
        let metadata_key = directory + `/${global.DIR_VIEWS}/` + dir + '/metadata.json';
        let metadata_exists = await keyExistsInS3(idToken, region, idpool, metadata_key);
        if(metadata_exists){
          const input = {
            "Bucket": `${global.BUCKET}`,
            "Key": metadata_key
          };
          //console.log("input",input);
          await client.send(new DeleteObjectCommand(input));
        }
    
        let hierarchy_key = directory + `/${global.DIR_VIEWS}/` + dir + '/hierarchy.bin';
        let hierarchy_exists = await keyExistsInS3(idToken, region, idpool, hierarchy_key);
        if(hierarchy_exists){
          const input = {
            "Bucket": `${global.BUCKET}`,
            "Key": hierarchy_key
          };
          //console.log("input",input);
          await client.send(new DeleteObjectCommand(input));
        }
    
        let octree_key = directory + `/${global.DIR_VIEWS}/` + dir + '/octree.bin';
        let octree_exists = await keyExistsInS3(idToken, region, idpool, octree_key);
        if(octree_exists){
          const input = {
            "Bucket": `${global.BUCKET}`,
            "Key": octree_key
          };
          //console.log("input",input);
          await client.send(new DeleteObjectCommand(input));
        }

        let error_key = directory + `/${global.DIR_VIEWS}/` + dir + '/error_potree_converter.txt';
        let error_exists = await keyExistsInS3(idToken, region, idpool, error_key);
        if(error_exists){
          const input = {
            "Bucket": `${global.BUCKET}`,
            "Key": error_key
          };
          //console.log("input",input);
          await client.send(new DeleteObjectCommand(input));
        }

        let dir_key = directory + `/${global.DIR_VIEWS}/` + dir + '/';
        let dir_exists = await keyExistsInS3(idToken, region, idpool, dir_key);
        if(dir_exists){
          const input = {
            "Bucket": `${global.BUCKET}`,
            "Key": dir_key
          };
          //console.log("input",input);
          await client.send(new DeleteObjectCommand(input));
        }
    };  
    
 export async function sendTokenByEmail(email)
 {
  const client = new CognitoIdentityProviderClient({region: global.REGION});
  const input = { 
    ClientId: `${global.CLIENTID}`, 
    Username: email, 
  };
  const command = new ForgotPasswordCommand(input);
  await client.send(command);
 }  
 
 export async function setNewPassword(email,code,password)
 {
    const client = new CognitoIdentityProviderClient({region: global.REGION});
    const input = { 
      ClientId: `${global.CLIENTID}`, 
      Username: email, 
      ConfirmationCode: code, 
      Password: password, 
    };
    const command = new ConfirmForgotPasswordCommand(input);
    await client.send(command);
  }    