
import * as React from 'react';
import axios from "axios";
import { useEffect, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AuthContext } from "../Auth/AuthContext";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import SvgIcon from '@mui/material/SvgIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { SideNavAdminItem } from './side-nav-admin-item';
import {formatSizeUnits} from '../utils/fileManagement'
import logo from "../assets/img/apple.png";

import FilePresentIcon from '@mui/icons-material/FilePresent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircleStackIcon from '@heroicons/react/24/solid/CircleStackIcon';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {getStorageTotal} from "../utils/interfaceS3";

export default function SideNavAdmin({ onClose, open, users, userSelected, setUserSelected }) {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [selectedindex, setselectedindex] = React.useState(0);

  const {  t } = useTranslation();

  const {idToken, region, idpool, jwt} = useContext(AuthContext);

  const [sizeUpload, setSizeUpload] = useState(0);
  const [sizeView, setSizeView] = useState(0);
  const [sizeProduct, setSizeProduct] = useState(0);
  const [sizeTotal, setSizeTotal] = useState(0);

  let dataDoughnut=[]
  if (sizeUpload>1000000){
    dataDoughnut.push({name:"Upload",value:sizeUpload})
  }
  if (sizeView>1000000){
    dataDoughnut.push({name:"Viewer",value:sizeView})
  }
  if (sizeProduct>1000000){
    dataDoughnut.push({name:"Products",value:sizeProduct})
  }

  function getUnViewedData(user) {
    let n = user.projects.reduce((acc, project) => {
      const projectData = project.data || [];
      projectData.forEach(dataItem => {
        if (dataItem.viewedByAdmin === false) {
          acc++;
        }
      });
      return acc;
    }, 0);
    return n;    
  }

  function updateViewedByAdminField(dataId)
  {
    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify({
      viewedByAdmin: true
    }))
    axios.put(`${global.API_URL}/api/datas/${dataId}`, dataForm, {
        headers: {
            Authorization: `Bearer ${jwt}`,
        }
    })
    .then(res => {
      return Promise.resolve();
    })
    .catch(err => {
        console.log(err);
    });
  };

  useEffect(() => {
    getStorageTotal(idToken, region, idpool).then(res => {   
      setSizeUpload(res.sizeUpload);  
      setSizeView(res.sizeView);    
      setSizeProduct(res.sizeProduct);  
      setSizeTotal(res.sizeUpload+res.sizeView+res.sizeProduct); 
    })
    if (userSelected && userSelected !== undefined) setselectedindex(userSelected.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  const handlelistitemclick = (event, value) => {
    setselectedindex(value.id);
    setUserSelected(value)
    let userTmp=users.find(x => x.id === value.id);
    userTmp.projects.forEach(projectData => {
      projectData.data.forEach(dataItem => {
        if (dataItem.viewedByAdmin === false) {
          //console.log("updating data id : ",dataItem.id);
          updateViewedByAdminField(dataItem.id);
        }
      });
    });
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 3 }}>
      <Box
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'space-between',
            pt: 2,
            px: 4
          }}
        >
          <Stack
          direction="row"
          alignItems="center"
          spacing={2}>
          <img
            component="img"
            alt="logo mirukuru"
            width="60px"
            height="60px"
            src={logo}
          />
          <Stack>
            <Typography
              color="inherit"
              variant="subtitle1"
            >
              <b>Mirukuru</b>
            </Typography>
            <Typography
              color="neutral.400"
              variant="body2"
            >
              {t("AdminPanel")}
            </Typography>
          </Stack>
          </Stack>
        </Box>
      </Box>
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 2
        }}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: 'none',
            p: 0,
            m: 0
          }}
        >
          {users.length > 0 &&
            <>
              {users.map((item, index) => {
                return (

                  <ListItemButton key={index}
                    onClick={(event) => handlelistitemclick(event, item)}
                  >
                    <SideNavAdminItem
                      active={selectedindex === item.id}
                      disabled={item.disabled}
                      external={item.external}
                      icon={item.icon}
                      path={item.username}
                      title={item.email}
                      admin={item.admin}
                      newData={getUnViewedData(item)}
                    />
                  </ListItemButton>
                );
              })}
            </>
          }
        </Stack>
      </Box>
      <Box
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'space-between',
            mt: 0,
            p: '20px',
            pl:3
          }}
        >
          
          <Stack spacing={1}>
            <Typography
              color="inherit"
              variant="subtitle1"
            >
              <b>{t("StorageStatistics")}</b>
            </Typography> 
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
            >
              <SvgIcon
                color="action"
                fontSize="small"
              >
                  <CloudUploadIcon />
              </SvgIcon>
              <Typography
                color="neutral.400"
                variant="body2"
              >
                {t("Upload")} <b>{formatSizeUnits(sizeUpload)}</b>
              </Typography>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
            >
              <SvgIcon
                color="action"
                fontSize="small"
              >
                  <VisibilityIcon />
              </SvgIcon>
              <Typography
                color="neutral.400"
                variant="body2"
              >
                {t("View")} <b>{formatSizeUnits(sizeView)}</b>
              </Typography>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
            >
              <SvgIcon
                color="action"
                fontSize="small"
              >
                  <FilePresentIcon />
              </SvgIcon>
              <Typography
                color="neutral.400"
                variant="body2"
              >
                {t("Products")} <b>{formatSizeUnits(sizeProduct)}</b>
              </Typography>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
            >
              <SvgIcon
                color="action"
                fontSize="small"
              >
                  <CircleStackIcon />
              </SvgIcon>
              <Typography
                color="neutral.400"
                variant="body2"
              >
                {t("Total")} <b>{formatSizeUnits(sizeTotal)}</b>
              </Typography>
            </Stack>
          </Stack>
          </Box>
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: '#212529',
            color: 'common.white',
            width: 340
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: '#212529',
          color: 'common.white',
          width: 340
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
