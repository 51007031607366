import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from './theme';
import Main from "./pages/Main";
import { AuthProvider } from "./Auth/AuthContext";

import { SnackbarProvider } from 'notistack';

// Material Kit 2 PRO React routes
import routes from "./routes";

import ViewerPageWrapper from "pages/ViewerPageWrapper"

import "./i18n";

export default function App() {
  const { pathname } = useLocation();

  const theme = createTheme();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
    });

  return (
    <AuthProvider>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider maxSnack={3} autoHideDuration={5000}   anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Main />} />
        <Route path="/viewer-page" element={<ViewerPageWrapper/>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </SnackbarProvider>
    </ThemeProvider> 
    </AuthProvider>
  );
}
