import * as React from 'react';
import { useState, useContext } from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import { AuthContext } from "../Auth/AuthContext";
import { uploadS3, fileExistsInS3 } from "../utils/interfaceS3";
import { newActivity } from "../utils/activityManagement";
import { FileUploader } from "react-drag-drop-files";
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import 'react-toastify/dist/ReactToastify.css';

import { alpha } from '@mui/material/styles';

export default function NewDataDialog({ setOpen, open, projectSelected, userId, reloadProjects }) {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { jwt, user, idToken, usage, maxSpace, maxFileSize, region, idpool, cloudinary_cloud, cloudinary_preset} = useContext(AuthContext);

    const [fileSelected, setFileSelected] = React.useState("");
    const [fileSize, setFileSize] = React.useState(0);
    const [description, setDescription] = useState("");

    const [imageSelected, setImageSelected] = React.useState("");

    const [isLoading, setIsLoading] = useState(false);

    const fileTypes = ["LAS", "LAZ", "las", "laz"];
    const handleChange = (file) => {
        //console.log(file);
        setFileSelected(file);
        setFileSize(file.size);
    };

    const imageTypes = ["PNG", "png", "JPG", "jpg", "JPEG", "jpeg"];
    const handleImageChange = (file) => {
        //console.log(file);
        setImageSelected(file);
    };

    const handleTypeError = () => {
        enqueueSnackbar(`${t("ErrorType")}`, { variant: 'warning' });
    };

    const handleClose = () => {
        if (!isLoading) {
            setOpen(false);
            setFileSelected("");
            setImageSelected("");
        }
    };

    function hasWhiteSpace(s) {
        return s.indexOf(' ') >= 0;
    }

    const handleCreate = () => {
        setIsLoading(true);
        //check if file has been selected
        if (typeof fileSelected.name == 'string') {

            //check if the filename contains ' '
            if (hasWhiteSpace(fileSelected.name)) {
                enqueueSnackbar(`${t("ErrorFileNoSpace")}`, { variant: 'warning' });
                setIsLoading(false);
            } else {
                //check if file exists   
                fileExistsInS3(user.sub, idToken, fileSelected.name).then(res => {
                    //console.log(res);
                    if (!res) {
                        if (parseFloat(fileSize / 1e9) < maxFileSize) {

                            if (parseFloat(usage) + parseFloat(fileSize / 1e9) < maxSpace) {
                                newData(fileSelected.name, fileSize, description);
                            } else {
                                enqueueSnackbar(`${t("ErrorSpace")}`, { variant: 'warning' });
                                setIsLoading(false);
                            }
                        } else {
                            enqueueSnackbar(`${t("ErrorMaxFileSpace")}`, { variant: 'warning' });
                            setIsLoading(false);
                        }
                    } else {
                        enqueueSnackbar(`${t("ErrorAlreadyExists")}`, { variant: 'warning' });
                        setIsLoading(false);
                    }
                })
            }
        } else {
            enqueueSnackbar(`${t("ErrorNoFile")}`, { variant: 'warning' });
            setIsLoading(false);
        }
    };

    const [uploadProgress, setUploadProgress] = React.useState(0);

    const uploadImage = async (imageSelected) => {
        const formData = new FormData();
        formData.append("file", imageSelected);
        formData.append("upload_preset", cloudinary_preset);
        
        const response = await axios.post(`https://api.cloudinary.com/v1_1/${cloudinary_cloud}/image/upload`, formData);
        return response.data.secure_url;
    };
    
    const createDataForm = (filename, size, description, optimizeImage = null) => {
        const dataForm = new FormData();
        const data = {
            filename: filename,
            size: size,
            description: description,
            viewable: false,
        };
        if (optimizeImage) {
            data.imageUrl = optimizeImage;
        }
        dataForm.append('data', JSON.stringify(data));
        return dataForm;
    };
    
    const saveData = async (dataForm) => {
        let response = await axios.post(`${global.API_URL}/api/datas`, dataForm, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            }
        });
        return response;
    };
    
    async function newData(filename, size, description) {
        try {
            let optimizeImage = null;
    
            if (typeof imageSelected.name === 'string') {
                optimizeImage = await uploadImage(imageSelected);
            }
    
            await uploadS3(user.sub, idToken, region, idpool, filename, fileSelected, setUploadProgress);
    
            const dataForm = createDataForm(filename, size, description, optimizeImage);
    
            const response = await saveData(dataForm);
    
            updateProjectWithNewData(projectSelected.id, response.data.data.id);
            newActivity(userId, jwt, filename, "upload");
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            enqueueSnackbar(`${t("ErrorUpload")}`, { variant: 'error' });
        }
    }

    async function updateProjectWithNewData(idProject, idData) {

        let project = await axios.get(`${global.API_URL}/api/projects/${idProject}?populate=*`, {
            headers: {
                Authorization:
                    `Bearer ${jwt}`,
            },
        });
        //Add the id to the previous list of ids
        let newDataIds = [];
        let listFiles = project.data.data.attributes.data.data;
        for (let i = 0; i < listFiles.length; i += 1) {
            newDataIds.push(listFiles[i].id);
        }
        newDataIds.push(idData);
        const dataForm = new FormData();
        dataForm.append('data', JSON.stringify({
            data: newDataIds,
        }))
        await axios.put(`${global.API_URL}/api/projects/${idProject}`, dataForm, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        setIsLoading(false);
        enqueueSnackbar(`${t("UploadSuccess")}`, { variant: 'success' });
        setFileSelected("");
        setFileSize(0);
        setDescription("");
        reloadProjects();
        setOpen(false);
    }

    return (
        <>

            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                    open={isLoading}
                >
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                        alignContent="center"
                    >
                        <Grid xs={12} align="center" sx={{ py: 3 }}>
                            <CircularProgress color="inherit" size="5rem" />
                        </Grid>
                        <br />
                        <Grid xs={12} align="center">
                            <Typography variant="h5" color="inherit">
                                <b>{t("UploadingContent")} : {uploadProgress} %</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Backdrop>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h4" component={"div"}>
                            {t("UploadData")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("SelectALASFile")}
                            </Typography>
                        </DialogContentText>
                        <Stack spacing={2} sx={{ mt: 3 }}>
                            <Stack spacing={1}>
                                <Typography
                                    color="text.secondary"
                                    variant="h6"
                                >
                                    {t("LASLAZFile")}
                                </Typography>
                                <FileUploader
                                    handleChange={handleChange}
                                    onTypeError={handleTypeError}
                                    multiple={false}
                                    name="file"
                                    types={fileTypes}
                                    hoverTitle=" "
                                    children={
                                        <Box>
                                            <Paper variant="outlined"
                                                sx={{
                                                    borderColor: '#E5E7EB',
                                                    borderStyle: 'solid',
                                                    borderWidth: 1,
                                                    '&:hover': {
                                                        backgroundColor: alpha('#111927', 0.04)
                                                    }
                                                }}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ minHeight: '100px', padding: 20 }}
                                                >

                                                    <Stack
                                                        alignItems="center"
                                                        direction="row"
                                                        spacing={2}
                                                    >
                                                        <CloudUploadIcon style={{ color: 'gray' }} /><Typography variant="body2" color="text.secondary">{t("dragndrop")}</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Paper>
                                        </Box>}
                                />
                                {fileSelected &&
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                    >
                                        <AttachmentIcon style={{ color: 'gray' }} />
                                        <Typography variant="body2" color="text.secondary"><b>{fileSelected.name}</b></Typography>
                                    </Stack>}
                            </Stack>  
                            <Stack spacing={1}>
                                <Typography
                                color="text.secondary"
                                variant="h6"
                                >
                                    {t("Description")}
                                </Typography>
                                <TextField
                                    fullWidth                            
                                    name="description"
                                    type="text"
                                    multiline
                                    rows={3}
                                    value={description}
                                    onChange={(event) => { setDescription(event.target.value) }}
                                />
                            </Stack> 
                            <Stack spacing={1}>
                                <Typography
                                color="text.secondary"
                                variant="h6"
                                >
                                    {t("Image")}
                                </Typography>
                                <FileUploader
                                    handleChange={handleImageChange}
                                    multiple={false}
                                    name="image"
                                    types={imageTypes}
                                    hoverTitle=" "
                                    children={
                                        <Box>
                                            <Paper variant="outlined"
                                                sx={{
                                                    borderColor: '#E5E7EB',
                                                    borderStyle: 'solid',
                                                    borderWidth: 1,
                                                    '&:hover': {
                                                        backgroundColor: alpha('#111927', 0.04)
                                                    }
                                                }}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ minHeight: '100px', padding: 20 }}
                                                >

                                                    <Stack
                                                        alignItems="center"
                                                        direction="row"
                                                        spacing={2}
                                                    >
                                                        <ImageIcon style={{ color: 'gray' }} /><Typography variant="body2" color="text.secondary">{t("dragndrop")}</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Paper>
                                        </Box>}
                                />
                                {imageSelected &&
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                    >
                                        <AttachmentIcon style={{ color: 'gray' }} />
                                        <Typography variant="body2" color="text.secondary"><b>{imageSelected.name}</b></Typography>
                                    </Stack>}
                            </Stack>     
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                        <Button disabled={isLoading} variant="contained" onClick={handleCreate}>{isLoading ? `${t("Uploading")}` : `${t("Upload")}`}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}