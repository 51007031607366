import ClockIcon from '@heroicons/react/24/solid/ClockIcon';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import MoreVert from "@mui/icons-material/MoreVert"
import { usePopover } from '../hooks/use-popover';
import { ProductPopover } from './product-popover';

import { FileIcon } from '../fileIcon/FileIcon';
import defaultStyles from '../fileIcon/defaultStyles';

export default function ProductCard({ data }) {

  const smUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));

  const productPopover = usePopover();

  const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  }

  const handleClickVertButton = (event) => {
    productPopover.handleOpen();
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            overflow: "hidden",
            "& .MuiCardHeader-content": {
              overflow: "hidden"
            }
          }}
          action={
            <div>
              <IconButton aria-label="settings" onClick={handleClickVertButton} ref={productPopover.anchorRef}>
                <MoreVert />
              </IconButton>
            </div>
          }
          title={

            <Typography
              align="center"
              gutterBottom
              variant="h5"
            >
              {data.filename.substring(data.filename.lastIndexOf('/')+1)}
            </Typography>}
          subheader={
            <Typography
              align="center"
              variant="body1"
              color="text.secondary"
            >
              {data.description}
            </Typography>
          }
        />
        {smUp &&<CardContent>
          <Box
            sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  px: 10
              }}
          >
          <Box
            sx={{
                  maxWidth:'80px'
              }}
          >
            <FileIcon extension={`${data.filename.split('.').pop()}`} {...defaultStyles[`${data.filename.split('.').pop()}`]} />
        </Box></Box>
        </CardContent>}
        {!smUp && 
        <Box sx={{ p: 1 }}></Box>
        }
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ p: 2 }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
          >
            <SvgIcon
              color="action"
              fontSize="small"
            >
              <ClockIcon />
            </SvgIcon>
            <Typography
              color="text.secondary"
              display="inline"
              variant="body2"
            >
              {getFormattedDate(data.createdAt)}
            </Typography>
          </Stack>
        </Stack>
      </Card>
      <ProductPopover
        anchorEl={productPopover.anchorRef.current}
        open={productPopover.open}
        onClose={productPopover.handleClose}
        data={data}
      />
    </>
  );
};