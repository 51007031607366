import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import Card from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SvgIcon from '@mui/material/SvgIcon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  useMediaQuery
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { useTranslation } from "react-i18next";

export default function ProductsSearch({view,setView,filter,setFilter,sort,setSort}) { 

  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const {  t } = useTranslation();
  
  const handleChange = (event, newDisplay) => {
    if(newDisplay)setView(newDisplay);
  };

  const serachBar=(
    <OutlinedInput
    value={filter}
    onChange={(e) => setFilter(e.target.value)}
    fullWidth
    placeholder={t("Searchfile")}
    startAdornment={(
      <InputAdornment position="start">
        <SvgIcon
          color="action"
          fontSize="small"
        >
          <MagnifyingGlassIcon />
        </SvgIcon>
      </InputAdornment>
    )}
  />
  );

  const toggleView=(
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleChange}
      size="large"
    >
      <ToggleButton value="grid">
        <GridViewIcon />
      </ToggleButton>
      <ToggleButton value="list">
        <FormatListBulletedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const filterList=(
    <FormControl sx={{ m: 1, minWidth: 120 }}>
    <InputLabel id="demo-simple-select-label">{t("Sortby")}</InputLabel>
    <Select 
    labelId="demo-simple-select-label" 
    id="demo-simple-select-helper" 
    label="Sort by"
    value={sort}
    onChange={(e) => setSort(e.target.value)}>    
      <ListSubheader sx={{backgroundColor: grey[100]}}>
        <Typography color="text.secondary" variant="body1">{t("Date")}</Typography>
      </ListSubheader>
      <MenuItem value="new"><Typography color="text.primary" variant="body2">{t("Latest")}</Typography></MenuItem>
      <MenuItem value="old"><Typography color="text.primary" variant="body2">{t("Oldest")}</Typography></MenuItem>
    </Select>
  </FormControl>
  );

  return (
  <Card sx={{ p: 2,
    '&:hover': {
      boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.06), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.04)',
      transform: 'none',
      }
   }}>
    {smUp && 
        <Stack
        direction="row"
        spacing={2}
        >
          {serachBar}
          {toggleView}
          {filterList}
        </Stack>  
    }
    {!smUp && 
        <Stack
        spacing={2}
        >
          {serachBar}
          {filterList}
        </Stack>  
    }
  </Card>
  );
};
