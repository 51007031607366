
import * as React from 'react';
import { useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItemButton from '@mui/material/ListItemButton';
import AddIcon from '@mui/icons-material/Add';
import { SideNavItem } from './side-nav-item';
//import { SideNavSubItem } from './side-nav-sub-item';
import Usage from "../components/usage.js";
import logo from "../assets/img/apple.png";
import { AuthContext } from "../Auth/AuthContext";

export default function SideNav({ userHasNoProject, handleClickOnNewProject, onClose, open, projects, projectSelected, setProjectSelected, usage, maxSpace }) {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const { demo } = useContext(AuthContext);

  const [selectedindex, setselectedindex] = React.useState(0);

  const {  t } = useTranslation();

  //console.log(projects);

  useEffect(() => {
    if (projectSelected && projectSelected !== undefined) setselectedindex(projectSelected.id);
  }, [projectSelected]);

  const handlelistitemclick = (event, value) => {
    setselectedindex(value.id);
    setProjectSelected(value);
    //console.log(value);
  };

  const handleNewProjectClick = (event) => {
    handleClickOnNewProject();
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'space-between',
            pt: 2,
            px: 3
          }}
        >
          <Stack
          direction="row"
          alignItems="center"
          spacing={2}>
          <img
            component="img"
            alt="logo mirukuru"
            width="60px"
            height="60px"
            src={logo}
          />
          <Stack>
            <Typography
              color="inherit"
              variant="subtitle1"
            >
              <b>Mirukuru</b>
            </Typography>
            <Typography
              color="neutral.400"
              variant="body2"
            >
              {t("OnlineStorage")}
            </Typography>
          </Stack>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          px: 3,
          py: 2
        }}
      >
          <Button disabled={demo} variant="contained" fullWidth sx={{ mt: 0 }} startIcon={<AddIcon />} onClick={(event) => handleNewProjectClick(event)}>
            {t("NewProject")}
          </Button>
      </Box>
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 1
        }}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: 'none',
            p: 0,
            m: 0
          }}
        >
          {userHasNoProject &&
            <Typography
              color="text.secondary"
              variant="body1"
              sx={{ p: 1 }}
              style={{ wordWrap: "break-word" }}
            >
              {t("NoProjectYet")}
            </Typography>

          }
          {projects.length > 0 &&
            <>
              {projects.map((item, index) => {
                return (
                  <ListItemButton key={index}
                    onClick={(event) => handlelistitemclick(event, item)}
                  >
                    <Stack>
                    <SideNavItem
                      active={selectedindex === item.id}
                      disabled={item.attributes.disabled}
                      external={item.attributes.external}
                      icon={item.attributes.icon}
                      path={item.attributes.path}
                      title={item.attributes.title}
                      empty={item.attributes.data.data.length}
                    />
                    {/* {item.attributes.data.data.length > 0 &&
                    <>
                      {item.attributes.data.data.map((file, index) => { 
                        return(
                          <SideNavSubItem file={file.attributes} active={selectedindex === item.id} key={index}/>
                        );       
                      })}
                    </>
                    } */}
                    </Stack>
                  </ListItemButton>
                );
              })}
            </>
          }
        </Stack>
      </Box>
      <Box
        sx={{
          px: 3,
          py: 2,
          
        }}
      >
        <Usage value={usage} max={maxSpace}/>
      </Box>
      
      {/* <Box
        sx={{
          px: 3,
          pt: 0
        }}
      >
        <Grid xs item textAlign='center'>
          <Typography
            color="neutral.100"
            variant="subtitle2"
          >
            {t("Needmorespace")}
          </Typography>
          <Typography
            color="neutral.500"
            variant="body2"
          >
            {t("Getintouchwithus")}
          </Typography>
        </Grid>
        <Button
          component="a"
          fullWidth
          onClick={() => window.location.href = 'mailto:contact-user-guest@mirukuru.co.jp'}
          sx={{ my: 2 }}
          target="_blank"
          variant="contained"
        >
          {t("ContactUs")}
        </Button>
      </Box> */}
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: '#212529',
            color: 'common.white',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: '#212529',
          color: 'common.white',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
