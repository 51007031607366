import * as React from 'react';
import axios from "axios";
import { useState, useEffect, useContext} from 'react';
import { AuthContext } from "../Auth/AuthContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import Slider from '@mui/material/Slider';

export default function UserSettingDialog({ setOpen, open, user, reloadUsers}) {

    const {  t } = useTranslation();
    const {jwt} = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setValue(user.space)
    }, [user]);

    const [value, setValue] = useState(user.space);

    function valuetext(value) {
        return `${value}Go`;
      }

    const handleSave = () => {
        setOpen(false);
        const data = {
            space: value,
        };
        axios.put(`${global.API_URL}/api/users/${user.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            }
        })
        .then(res => {
            enqueueSnackbar(`${t("SuccessEditUser")}`, {variant:'success'} );
            reloadUsers();
        })
        .catch(err => {
            enqueueSnackbar(`${t("ErrorEditUser")}`, {variant:'error'} );
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue, activeThumb) => {
          setValue(newValue);
      };

    const marks = [
        {
          value: 10,
          label: '10 Go',
        },
        {
          value: 100,
          label: '100 Go',
        },
      ];

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h3" component={"div"}>
                        {t("UserSettings1")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                    <Box sx={{ py: 2 }}>
                        <Typography variant="h5">
                        {t("UserSettings2")}
                        </Typography>
                        <Typography color="text.secondary" variant="body2" sx={{ pt: 1 }}>
                        {t("UserSettings3")}
                        </Typography>
                        <Box sx={{pr: 4 , pt: 2 }}>
                        <Slider
                            aria-label="Storage"
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            step={10}
                            marks={marks}
                            min={10}
                            max={100}
                            value={value}
                            onChange={handleChange}
                        />
                        </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                        <Button variant="contained" color="primary" onClick={handleSave}>{t("Save")}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}