
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVert from "@mui/icons-material/MoreVert";
import { usePopover } from '../hooks/use-popover';
import { ProductPopoverAdmin } from './product-popover-admin';


export default function ProductRowAdmin({ id, data, userid, loadProducts }) {

    const filePopover = usePopover();

    const handleClickVertButton = (event) => {
        filePopover.handleOpen();
    };

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString();
    }

    return(
        <>
        <TableRow
        hover
        key={id}
        >
        <TableCell>
            <Typography
                            color="text.primary"                           
                            variant="body1"
                        >  
            <b>{data.attributes.filename.substring(data.attributes.filename.lastIndexOf('/')+1)}</b>
            </Typography>
        </TableCell>
        <TableCell>
            <Typography
                            color="text.secondary"                           
                            variant="body2"
                        >   
            {data.attributes.description}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography
                            color="text.secondary"                           
                            variant="body2"
                        >   
            {getFormattedDate(data.attributes.createdAt)}
            </Typography>
        </TableCell>
        <TableCell align="right">
            <IconButton aria-label="settings" onClick={handleClickVertButton} ref={filePopover.anchorRef}>
                <MoreVert />
            </IconButton>
        </TableCell>
        </TableRow>
        <ProductPopoverAdmin
        anchorEl={filePopover.anchorRef.current}
        open={filePopover.open}
        setOpen={filePopover.setOpen}
        onClose={filePopover.handleClose}
        data={data}
        userid={userid}
        loadProducts={loadProducts}
        />
        </>
    )
};