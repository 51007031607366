export function formatSizeUnits(bytes){
    if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(1) + " GB"; }
    else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(1) + " MB"; }
    else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(1) + " KB"; }
    else if (bytes > 1)           { bytes = bytes + " bytes"; }
    else if (bytes === 1)          { bytes = bytes + " byte"; }
    else                          { bytes = "0 bytes"; }
    return bytes;
}

export function formatSizeToGb(bytes){
    return Math.round(bytes / 1073741824*100)/100;
}