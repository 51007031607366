import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import logo from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import {sendTokenByEmail} from "../utils/interfaceS3";

export default function ResetPassword() {

    const { i18n , t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('languageMirukuru') !== null) {          
            i18n.changeLanguage(localStorage.getItem('languageMirukuru'));
        }
    }, [i18n]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        try{
            await sendTokenByEmail(data.get('email'));
            enqueueSnackbar(`${t("SuccessResetPassword")}`, {variant:'success'} );
            navigate("/new-password",{state:{email:data.get('email')}})
        }catch (e) {
            if (e.name === 'LimitExceededException') {
                enqueueSnackbar(`${t("TooManyAttempt")}`, {variant:'warning'} );
            }else{
                enqueueSnackbar(`${t("ErrorResetPassword")}`, {variant:'error'} );
            }
        }
      }

    const content = (
        <>

            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
                sx={{ px: 3, maxWidth: '700px' }}
            >

                <Grid xs={12}>
                <CardMedia
                        component="img"
                        alt="logo mirukuru"
                        width="100px"
                        image={logo}
                        sx={{ px:4, py:3 }}
                    />
                    <Card sx={{ p: 5 }}>

                        <Stack
                            spacing={3}
                            sx={{ mb: 3 }}
                        >
                            <Typography variant="h4">
                            {t("ResetPassword")}
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("ResetPasswordDesc")}
                            </Typography>
                        
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    label={t("Email")}
                                    name="email"
                                    type="email"
                                />
                            </Stack>
                            <Button
                                fullWidth
                                size="large"
                                sx={{ mt: 3 }}
                                type="submit"
                                variant="contained"
                            >
                                {t("Send")}
                            </Button>
                        </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </>
    );

        return (
            <>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                        {content}
                </Grid>
            </>
        );
}