import * as React from 'react';
import axios from "axios";
import { useState, useContext } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import {deleteFileS3,deleteProductS3} from "../utils/interfaceS3";

export default function DeleteFileDialog({ setOpen, open, data, reloadProjects }) {

    const {  t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const {jwt,user,idToken, region, idpool} = useContext(AuthContext);

    const handleClose = () => {
        setOpen(false);
    };

    //console.log(data);

    const deleteEntry = async value => {
        setIsLoading(true);
        console.log(data);

        // Delete associated result files from S3
        const results = data.attributes.results?.data || [];
        for (const result of results) {
            const apiUrl = `${global.API_URL}/api/results/${result.id}`;
            await axios.delete(apiUrl, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                }
            });
            const filenameWithExt = result.attributes.filename.split('/').pop();
            await deleteProductS3(user.sub, idToken, region, idpool, filenameWithExt);
        }

        await deleteFileS3(user.sub,idToken, region, idpool,`${data.attributes.filename}`);
        const apiUrl = `${global.API_URL}/api/datas/${data.id}`;
        axios.delete(apiUrl, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            }
        })
            .then(res => {
                setIsLoading(false);
                enqueueSnackbar(`${t("successDeleteFile")}`, {variant:'success'} );
                setOpen(false);
                reloadProjects();             
            })
            .catch(err => {
                setIsLoading(false);
                enqueueSnackbar(`${t("errorDeleteFile")}`, {variant:'error'} );
            });
    }

    return (

        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
            <Box sx={{ p: 3 }}>
                <DialogTitle>
                    <Typography variant="h4" component={"div"}>
                        {t("DeleteFile")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={"div"}>
                        <Typography
                            color="text.secondary"
                            variant="body2"
                        >
                            {t("DeleteFileDesc")}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                    <Button variant="contained" color="error" onClick={deleteEntry}>{isLoading ? `${t("Deleting")} ...` : `${t("Delete")}`}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}