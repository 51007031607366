import Login from "./pages/Login";
import Main from "./pages/Main";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./pages/NewPassword";

const routes = [
    {
    name: "login",
    route: "/login",
    component: <Login />,
    key:1
    },
    {
    name: "main",
    route: "/",
    component: <Main />,
    key:2
    },
    {
    name: "reset",
    route: "/reset-password",
    component: <ResetPassword />,
    key:3
    },
    {
    name: "new",
    route: "/new-password",
    component: <NewPassword />,
    key:4
    },
];
export default routes;        