import { useTranslation } from "react-i18next";
import axios from "axios";
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const NotificationPopover = (props) => {
  const { anchorEl, onClose, open, projects, onUpdateNotificationCount } = props;
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);

  const {jwt} = useContext(AuthContext);

  useEffect(() => {
    setNotifications(projects);
  }, [projects]);

  const updateViewedField = async (projectId, resultId) => {
    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify({
        viewed: true
    }))
    axios.put(`${global.API_URL}/api/results/${resultId}`, dataForm, {
        headers: {
            Authorization: `Bearer ${jwt}`,
        }
    })
    .then(res => {
      return Promise.resolve();
    })
    .catch(err => {
        console.log(err);
    });
    
  };

  const handleRemoveNotification = async (projectIndex, dataItemIndex, resultIndex) => {
    const updatedNotifications = [...notifications];
    const project = updatedNotifications[projectIndex];
    const dataItem = project.attributes.data.data[dataItemIndex];
    const resultItem = dataItem.attributes.results.data[resultIndex];

    await updateViewedField(project.id, resultItem.id);

    resultItem.attributes.viewed = true;
    const filteredNotifications = updatedNotifications.filter(proj => proj.attributes.data.data.some(data =>
      data.attributes.results.data.some(result => !result.attributes.viewed)));

    setNotifications(filteredNotifications);
    onUpdateNotificationCount(filteredNotifications.reduce((acc, project) => {
      const projectData = project.attributes.data.data || [];
      projectData.forEach(dataItem => {
        const resultsData = dataItem.attributes.results.data || [];
        resultsData.forEach(resultItem => {
          if (resultItem.attributes.viewed === false) {
            acc++;
          }
        });
      });
      return acc;
    }, 0));
  };

  const viewableNotifications = notifications.reduce((acc, project, projectIndex) => {
    const projectData = project.attributes.data.data || [];
    projectData.forEach((dataItem, dataItemIndex) => {
      const resultsData = dataItem.attributes.results.data || [];
      resultsData.forEach((resultItem, resultIndex) => {
        if (resultItem.attributes.viewed === false) {
          const fullPath = resultItem.attributes.filename;
          const fileName = fullPath.split('/').pop();
          acc.push({
            projectIndex,
            dataItemIndex,
            resultIndex,
            projectName: project.attributes.title,
            resultName: fileName,
            fileName: dataItem.attributes.filename
          });
        }
      });
    });
    return acc;
  }, []);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { mt: 1, width: 500 } }}
    >
      <List>
        {viewableNotifications.length > 0 ? (
          viewableNotifications.map((notification, index) => (
            <div key={index}>
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    size="small"
                    onClick={() => handleRemoveNotification(
                      notification.projectIndex,
                      notification.dataItemIndex,
                      notification.resultIndex
                    )}
                  >
                    <CloseIcon fontSize="small"/>
                  </IconButton>
                }
              >
                <Stack sx={{ p: 1 }}>
                  <Typography variant="overline">
                    {notification.projectName}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                  >
                    {notification.resultName} {t("HasBeenAddedToTheFile")} {notification.fileName}
                  </Typography>
                </Stack>
              </ListItem>
              {index < viewableNotifications.length - 1 && <Divider />}
            </div>
          ))
        ) : (
          <ListItem>
            <ListItemText primary={t('No more notifications')} />
          </ListItem>
        )}
      </List>
    </Popover>
  );
};

NotificationPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    attributes: PropTypes.shape({
      title: PropTypes.string.isRequired,
      data: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
          attributes: PropTypes.shape({
            filename: PropTypes.string.isRequired,
            results: PropTypes.shape({
              data: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                attributes: PropTypes.shape({
                  filename: PropTypes.string.isRequired,
                  description: PropTypes.string.isRequired,
                  viewed: PropTypes.bool
                }).isRequired
              })).isRequired
            }).isRequired
          }).isRequired
        })).isRequired
      }).isRequired
    }).isRequired
  })).isRequired,
  onUpdateNotificationCount: PropTypes.func.isRequired
};