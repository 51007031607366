import { useState,useContext } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Backdrop from '@mui/material/Backdrop';

import EditFileDialog from "../dialog/edit-file-dialog.js"
import ShareFileDialog from "../dialog/share-file-dialog.js"
import DeleteFileDialog from "../dialog/delete-file-dialog.js"
import ProductsDialog from "../dialog/products-dialog.js"
import ErrorViewerDialog from "../dialog/error-viewer-dialog.js"
// import PotreeDialog from '../dialog/potree-dialog.js'

import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { AuthContext } from "../Auth/AuthContext";
import {getPresignedURLS3,getPresignedURLForViewer,isViewerReady,isViewerError} from "../utils/interfaceS3";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

export const FilePopover = (props) => {

  const {  t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const {user,idToken, region, idpool, demo } = useContext(AuthContext);

  const { anchorEl, onClose, open, data, reloadProjects } = props;

  const [isViewerLoading, setIsViewerLoading] = useState(false);
  const [openDialogShareFile, setOpenDialogShareFile] = useState(false);
  const [openDialogEditFile, setOpenDialogEditFile] = useState(false);
  const [openDialogDeleteFile, setOpenDialogDeleteFile] = useState(false);
  const [openDialogProducts, setOpenDialogProducts] = useState(false);
  const [openDialogErrorViewer, setOpenDialogErrorViewer] = useState(false);
  // const [openDialogViewer, setOpenDialogViewer] = useState(false);
  // const [dataViewer, setDataViewer] = useState({});
  const [urlLink, setUrlLink] = useState("");

  async function handleDownloadFileClick () {
    try{
      //console.log(`${data.attributes.filename}`);
      const url = await getPresignedURLS3(user.sub,idToken, region, idpool,`${data.attributes.filename}`,3600);
      window.location.href = url;
    }catch{
      enqueueSnackbar(`${t("errorDownloadFile")}`, {variant:'error'} );
    }
  };

  async function handleShareFileClick() {
    setOpenDialogShareFile(true);
    const url = await getPresignedURLS3(user.sub,idToken, region, idpool,`${data.attributes.filename}`,86400);
    setUrlLink(url);
    onClose();
  };

  const handleDeleteFileClick = num => {
    setOpenDialogDeleteFile(true);
    onClose();
  };

  const handleEditFileClick = num => {
    setOpenDialogEditFile(true);
    onClose();
  };

  const handleProductsClick = num => {
    setOpenDialogProducts(true);
    onClose();
  };

  async function handleView () {
    setIsViewerLoading(true);
    let viewerReady = await isViewerReady(user.sub,idToken, region, idpool,`${data.attributes.filename}`);
    if(viewerReady){
      const {metadata,hierarchy,octree} = await getPresignedURLForViewer(user.sub,idToken, region, idpool,`${data.attributes.filename}`,86400);
      const filename=data.attributes.filename;
      const description=data.attributes.description;
      setIsViewerLoading(false);
      //setDataViewer({ filename:filename, description:description, metadata:metadata, hierarchy:hierarchy, octree:octree });
      //setOpenDialogViewer(true);
      navigate("/viewer-page/", { state: { filename:filename, description:description, metadata:metadata, hierarchy:hierarchy, octree:octree } });     
    }else{

      let viewererror = await isViewerError(user.sub,idToken, region, idpool,`${data.attributes.filename}`);
      if(viewererror)
      {
        setIsViewerLoading(false);
        setOpenDialogErrorViewer(true);
      }else{
        setIsViewerLoading(false);
        enqueueSnackbar(`${t("ViewerNotReady")}`, {variant:'info'} );
      }
    }
    onClose();
  };

  return (
    <>
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={isViewerLoading}
      >     
        <Grid
        container
        alignItems="center"
        justify="center"
        alignContent="center"
        >
        <Grid xs={12} align="center" sx={{ py: 3}}>
        <CircularProgress color="inherit" size="5rem"/>
        </Grid>
        <Grid xs={12} align="center">
            <Typography variant="h5" color="inherit">
            <b>{t("GettingViewerReady")}</b>
            </Typography>
        </Grid>
        </Grid>
      </Backdrop>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 160 } }}
      >
        <MenuList
          disablePadding
          dense
          sx={{
            p: '8px',
            '& > *': {
              borderRadius: 1
            }
          }}
        >
            <MenuItem onClick={(event) => handleView(event)}>
              <ListItemIcon>
                <VisibilityIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("View")}</ListItemText>
            </MenuItem>
          {(data.attributes.results.data.length > 0) &&
            <MenuItem onClick={(event) => handleProductsClick(event)}>
              <ListItemIcon>
                <FilePresentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("Products")}</ListItemText>
            </MenuItem>}  
          <MenuItem disabled={demo} onClick={(event) => handleDownloadFileClick(event)}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Download")}</ListItemText>
          </MenuItem>
          <MenuItem disabled={demo} onClick={(event) => handleShareFileClick(event)}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Share")}</ListItemText>
          </MenuItem>
          <MenuItem disabled={demo} onClick={(event) => handleEditFileClick(event)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Edit")}</ListItemText>
          </MenuItem>
          <MenuItem disabled={demo} onClick={(event) => handleDeleteFileClick(event)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Delete")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      {/* <PotreeDialog open={openDialogViewer} onClose={setOpenDialogViewer} data={dataViewer}/> */}
      <ErrorViewerDialog open={openDialogErrorViewer} setOpen={setOpenDialogErrorViewer}/>
      <EditFileDialog open={openDialogEditFile} setOpen={setOpenDialogEditFile} data={data} reloadProjects={reloadProjects}/>
      <ShareFileDialog open={openDialogShareFile} setOpen={setOpenDialogShareFile} data={data} url={urlLink}/>
      <DeleteFileDialog open={openDialogDeleteFile} setOpen={setOpenDialogDeleteFile} data={data} reloadProjects={reloadProjects} />
      {(data.attributes.results.data.length > 0) && <ProductsDialog products={data.attributes.results.data} open={openDialogProducts} setOpen={setOpenDialogProducts} />}
    </>
  );
};

FilePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
